<template>
    <div class="col-12 d-flex flex-column justify-content-start align-items-center slide px-0">
        <div class="row justify-content-center caption row__width">
            <div class="col-12 d-flex justify-content-center align-items-center px-1 position-relative">
                <img :src="image" alt="" class="ejemplo">
                <div class="row justify-content-center row__width position">
                    <div class="col-12 d-flex justify-content-center align-items-center px-1" style="padding: 0 0;">
                        <div class="row justify-content-center row__width row__slide">
                            <div class="col-12 d-flex justify-content-between align-items-start arriba">
                                <img src="../../img/home-icons/cursos-icons/verificado.svg" alt="" v-if="membership">
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center abajo px-0">
                                <div class="row justify-content-center row__width">
                                    <div class="col-7 d-flex justify-content-start align-items-center" v-if="marketplace">
                                        <h4>{{ $t('message.produced') }}</h4><h5>{{ marketplace.translation.name }}</h5>
                                    </div>
                                    <div class="col-5 d-flex justify-content-end align-items-center" v-if="reviews">
                                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="image-fix"><h6>{{ reviews.average }}</h6><p>({{ reviews.count }})</p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                <div class="row justify-content-center row__width descripcion">
                    <div class="col-12 d-flex justify-content-start justify-content-lg-start align-items-start">
                        <h2 class="pt-2">{{ name }}</h2>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                        <div class="row justify-content-start row__width py-3">
                            <div class="col d-flex justify-content-start align-items-center">
                                <img :src="avatar" alt="" class="avatarImage">
                                <div class="d-flex flex-column justify-content-center align-items-start" >
                                    <h3>{{$t('message.creator')}}</h3>
                                    <h4>{{ owner }}<img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" v-if="verified"></h4>
                                </div>
                            </div>
                            <div class="btn_none col d-flex justify-content-end align-items-center colaboradores " v-if="collabs.length >= 1">
                                <img :src="collabs[0].avatar" alt="" class="img_ejemplo">
                                <div class="colaboran"><p>+{{ collabs.length }}</p></div>
                                <h3>{{$t('message.collaborate')}}</h3>
                            </div>
                        </div>
                        <span class="border-bottom"></span>
                    </div>
                </div>
                <div class="row justify-content-center row__width horarios">
                    <div class="col-12 d-flex justify-content-center align-items-center ">
                        <div class="row justify-content-center row__width py-2">
                            <div class="col d-flex justify-content-center align-items-center  col-perso px-lg-0">
                                <img src="../../img/home-icons/cursos-icons/participantes.svg" alt=""><h6>{{ purchases_count }}</h6><span class="border2"></span>
                                <div class="row justify-content-center justify-content-lg-start row__width">
                                    <div class="col-4 d-flex justify-content-center justify-content-lg-center align-items-center">
                                        <h3 class="line-title">{{ modality }}</h3>
                                    </div>
                                    <div class="col d-flex justify-content-center align-items-center px-0" v-if="details">
                                        <div class="d-flex justify-content-center align-items-center px-0">
                                            <img src="../../img/home-icons/cursos-icons/horario.svg" alt="" v-if="details.time_duration">
                                            <h6 v-if="details.time_duration">{{ details.time_duration }} {{ $t('message.hours') }}</h6>
                                        </div>
                                        <div class="d-flex justify-content-center align-items-center px-0 ms-4" v-if="details.schedules">
                                            <img src="../../img/home-icons/cursos-icons/calendario.svg" alt=""><h6>{{ details.schedules[0] }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center align-items-center comprar row__width" v-if="realPrice == 'GRATIS'">
                    <button class="mirar" @click="router.push({ name: 'HomeCursos', params: { slug: slug }})">
                        <span>{{$t('message.freeUppercase')}}</span>
                        <img src="../../img/home-icons/cursos-icons/mirar-ahora.svg" alt=""> {{ $t('message.lookNow') }}
                    </button>
                </div>
                <div class="row justify-content-start comprar row__width" v-else>
                    <div class="col-6 d-flex flex-column justify-content-center align-items-start padding" v-if="price">
                        <h2>{{ price.coin_type[0].abbr }} ${{ price.finalPrice }}</h2>
                        <h5>{{ price.coin_type[0].abbr }} ${{ price.totalPrice }}</h5>
                    </div>
                    <div class="col-6 d-flex justify-content-end align-items-center px-0">
                        <BTNPrimario url="/home-cursos/" :slug="slug" :textoBTNPrimario="$t('message.buy')" class="btn-width"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import BTNPrimario from "../Botones/PrimarioRutas.vue";
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";
import { useRouter } from 'vue-router';
import { onMounted } from "vue";

    const router = useRouter()

    const general = useGeneralStore();
    const {textos, filtrosDesplegados, mapaDesplegado} = storeToRefs(general);
    const {} = general;

    //Recibe los parametros de busqueda del componente padre
    const props = defineProps({
        rName: '',
        rSlug: '',
        rOwner: '',
        rOwnerAvatar: '',
        rImage: '',
        rModality: '',
        rDetail: '',
        rPrice: '',
        rVerified: '',
        rProductVerified: '',
        rCreatedAt: '',
        rCollabs: '',
        rReviews: '',
        rMarketplace: '',
        rMembership: '',
        rPurchases: ''
    })

    //Setea las variables con los resultados para su uso
    let name = props.rName
    let slug = props.rSlug
    let owner = props.rOwner
    let avatar = props.rOwnerAvatar
    let image = props.rImage
    let modality = props.rModality
    let details = props.rDetail
    let price = props.rPrice
    let verified = props.rVerified
    let productVerified = props.rProductVerified
    let createdAt = props.rCreatedAt
    let collabs = props.rCollabs
    let reviews = props.rReviews
    let marketplace = props.rMarketplace
    let membership = props.rMembership
    let purchases_count = props.rPurchases

    const roundHours = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (remainingMinutes >= 45) {
            return hours + 1;
        } else {
            return hours;
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('es-ES', { month: 'long' });

        // Capitaliza la primera letra del mes
        const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1);

        return `${day} ${formattedMonth}`;
    }

    onMounted(() => {
        if (details) {
            if (details.time_duration) {
                details.time_duration = roundHours(details.time_duration)
            }
            if (details.schedules) {
                details.schedules[0] = formatDate(details.schedules[0])
            }
        }
        
    })
    
    
    //console.log(props)
</script>

<style lang="scss" scoped>
.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    min-height: 465px;
    max-height: 70vh;
    .ejemplo{
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
        height: 240px;
    }
    .caption{
        position: relative;
        margin: 10px 0;
        padding: 0 5px;
        img{
            max-width: 100%;
        }
        .position{
            position: absolute;
            top: 0;
            min-height: 100%;
            h3{
                font-weight: 700;
                font-size: 16px;
                color: var(--bgPrincipal);
            }
            h4{
                font-weight: 400;
                font-size: 15px;
                color: var(--bgPrincipal);
            }
            h5{
                font-weight: 700;
                font-size: 15px;
                color: var(--bgPrincipal);
                padding-left: 5px;
            }
            h6{
                font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: #FEB230;
            }
            p{  font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: var(--bgPrincipal);
                padding-left: 3px;
            }
            .row__slide{
                position: relative;
                min-height: 100% !important;
                min-height: 250px;
                border-radius: 15px;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 80%);
                .arriba{
                    position: absolute;
                    top: 3%;
                }
                .abajo{
                    position: absolute;
                    bottom: 3%;
                }
            }
            
        }
    }
    .descripcion{
        
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.2;
            text-align: left;
        }
        h3{
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: start;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            img{
                margin-left: 5px;
            }
        }
        p{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .colaboradores{
            position: relative;
            max-width: 50%;
            .img_ejemplo{
                position: absolute;
                left: 2%;
                object-fit: cover;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                margin-right: 5px;
            }
            .colaboran{
                background-color: #697891;
                box-shadow: 0px 3px 6px #00000029;
                border: 2px solid #FFFFFF;
                border-radius: 50%;
                width: 42px;
                height: 42px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 18%;
            }
        }
        .creador{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
        }
        .border-bottom{
            width: 90%;
            height: 1px;
            background-color: rgba(184, 193, 219, 0.3);
        }
    }
    .horarios{
        h6{
            color: #7D93B2;
            font-weight: 500;
            font-size: 14px;
            padding-left: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .border2{
            width: 1px;
            height: 100%;
            background-color: rgba(184, 193, 219, 0.3);
            margin: 0 10px;
        }
        h3{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 15px;
        }
        .col-perso2{
            max-width: 25%;
        }
    }
    .comprar{
        margin-bottom: 5px;
        margin-top: 15px;
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
        }
        h5{
            font-size: 14px;
            font-weight: 400;
            color: #B8C1DB;
            text-decoration: line-through;
        }
        .padding{
            padding-left: 20px;
        }
    }
    @media (min-width: 992px) {
        padding: 5px 5px !important;
        .ejemplo{
            max-height: 240px;
        }
        .max-width-btn{
            max-width: 200px;
        }
    }
}
.line-title {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}
.btn-width {
    width: 150px;
}
.image-fix {
    margin: 0px 7px 2px 0px;
}
.max-width-col2{
    max-width: 400px
}
.mirar{
        border: 2px solid var(--colorSecondary);
        border-radius: 25px;
        background-color: var(--bgPrincipal);
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        width: 90%;
        position: relative;
        img{
            padding-right: 10px;
        }
        span{
           position: absolute;
           text-transform: uppercase;
           color: var(--colorPrimary);
           font-weight: 600;
           font-size: 14px;
           background-color: var(--bgPrincipal);
           top: -10px;
           right: 15px;
           min-width: 40%;
        }
    }

    .avatarImage{
        object-fit: cover;
        border-radius: 50%;
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        margin-right: 5px;
    }
</style>