<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start ps-lg-0">
        <NavbarCertificados :seccion="$t('message.myReferrals')"/>
        <div class="col d-none d-flex flex-column justify-content-center align-items-center align-items-lg-start classSpecial">
            <!--- AFILIADOS --->
            <form class="row justify-content-center align-items-center row__width contenedor">
                <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 ">
                    <div class="row justify-content-center row__width border-bottom h-100">
                        <!-- <AtrasAdmin/> -->
                        <div class="col-12 col-lg-7 px-0 d-flex flex-column justify-content-center align-items-center mb-4">
                            <TituloPanelAdmin :title="$t('message.manageUsers')" :subtitle="$t('message.seeAndManageUsers')"/>
                        </div>
                        <div class="col-12 col-lg-4 px-0 px-lg-auto d-flex justify-content-start justify-content-lg-end align-items-center">
                            <button type="button" class="boton-nuevo-usuario" @click="router.push('/nuevo-usuario')">
                                <span>
                                    <img src="../../../img/panel-icons/mas.svg" alt="">
                                </span>
                                {{ $t('message.addOtherLanguage') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                    <div class="row justify-content-center row__width h-100">
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-start my-4">
                            <h3>{{ $t('message.allRoles') }}</h3>
                            <h4>{{ $t('message.canAdministrateRoles') }}</h4>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-start px-5">
                    <div class="row justify-content-start align-items-start row__width h-100">
                        <div class="col col-lg-4 px-0 d-flex justify-content-center align-items-start" v-for="(card, id) in variable">
                            <div class="m-3 card">
                                <div class="row padding h-100">
                                    <div class="col col-lg-12 d-flex justify-content-start align-items-start mb-5">
                                        <h2>Administradores</h2>
                                    </div>
                                    <div class="col col-lg-12 d-flex justify-content-start align-items-start">
                                        <h5>5 usuarios</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col-lg-4 px-0 d-flex justify-content-center align-items-start">
                            <div class="m-3 card role">
                                <div class="row padding h-100">
                                    <div class="col col-lg-12 d-flex justify-content-end align-items-start mb-4">
                                        <h5>{{ $t('message.addNewRole') }}</h5>
                                    </div>
                                    <div class="col col-lg-12 d-flex justify-content-end align-items-start">
                                        <button type="button">+ {{ $t('message.addRole') }}</button>
                                    </div>
                                    <div class="col col-lg-12 d-flex justify-content-end align-items-start mb-4">
                                        <img src="../../../img/panel-admin-icons/new-role-icon.svg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <FooterCertificados :activeCertificados="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import AtrasAdmin from "../../../components/Botones/AtrasAdmin.vue";
import NavLateralPanelAdmin from "../../../components/Navs/NavLateralPanelAdmin.vue";
import TituloPanelAdmin from "../../../components/Titulo/TituloPanelAdmin.vue";

import { ref } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter()

const variable = ref(5)
</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
}
.contenedor{
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 40px;
    min-height: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    @media (min-width: 992px) {
        padding-top: 80px;
    }
    @media (min-width: 992px) {
        border-radius: 50px;
        padding: 22px 15px;
    }
    @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
    }
    h2{
        color: #374557;
        font-size: 22px;
        font-weight: 600;
        padding-left: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    h3{
        color: var(--colorSecondary);
        font-size: 22px;
        font-weight: 700;
        padding-left: 25px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    h4{
        color: #697891;
        font-size: 16px;
        font-weight: 500;
        padding-left: 25px;
        padding-bottom: 5px;
    }
    h5{
        color: #697891;
        font-size: 16px;
        font-weight: 500;
        padding-left: 5px;
        padding-bottom: 5px;
    }
    .boton-nuevo-usuario{
        box-shadow: 0 3px 6px #00000016;
        border-radius: 43px;
        height: 60px;
        margin-bottom: 30px;
        border: 0;
        background-color: var(--bgPrincipal);
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-bottom: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;
        width: 100%;
        max-width: 300px;
        span{
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
        }
    }
    .padding{
        padding: 20px;
        max-width: inherit;
        h2{
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .card{
        height: 144px;
        width: 100%;
        box-shadow: 0px 3px 10px #00000029;
        border: 2px solid #ECECEC;
        background-color: var(--bgPrincipal);
        border-radius: 20px;
    }
    .card:hover{
        border: 2px solid var(--colorPrimary);
        h2{
            color: var(--colorPrimary);
        }
    }
    .role{
        button{
            height: 50px;
            background-color: transparent;
            border: 2px solid var(--colorPrimary);
            border-radius: 25px;
            color: inherit;
            font-size: inherit;
            padding: 0;
            color: var(--colorPrimary);
            font-size: 16px;
            font-weight: 500;
            padding: 10px 20px;
        }
        img{
            position: absolute;
            left: 0px;
            bottom: 0px;
        }
    }
}
.classSpecial{
   @media (min-width: 992px) {
        width: 70vw !important;
   }
}
</style>