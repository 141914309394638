<template>
    <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0" id="questionarieView">
        <div class="row justify-content-center row__width" v-if="actualActivity != null && actualActivity.lessonType == 2">
            <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-4">
                <div class="row justify-content-start row__width">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0 mb-4">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 col-lg-6 col-xl-8 d-flex flex-column justify-content-start align-items-start px-0">
                                <h2>{{ $t('message.addQuestions') }}</h2>
                                <p>{{ $t('message.addQA') }}</p>
                            </div>
                            <div class="col-12 col-lg-6 col-xl-4 d-flex justify-content-center align-items-center px-0 my-2 my-lg-0">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div class="d-flex justify-content-between align-items-center w-100">
                                            <h3 class="">{{ $t('message.addQuestion') }}</h3>
                                            <i class="arrow"></i>
                                        </div>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a class="dropdown-item" href="#" @click="createQuestion(1)">
                                                <span>
                                                    <img src="../../../img/subir-productos-icons/cursos-icons/cuestionario.svg" alt="">
                                                </span>
                                                Simple
                                            </a></li>
                                            <li><a class="dropdown-item" href="#" @click="createQuestion(2)">
                                                <span>
                                                    <img src="../../../img/subir-productos-icons/cursos-icons/cuestionario.svg" alt="">
                                                </span>
                                                Multiple
                                            </a></li>
                                        </ul>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center collapses px-0">
                        <div v-for="(question, id) in actualActivity.questions" :key="question" class="accordion d-flex flex-column justify-content-center align-items-center row__width" id="accordionExample">
                            <!--- TIPO DE PREGUNTA SIMPLE --->
                            <div class="accordion-item w-100" v-if="question.type == 1">
                                <h2 class="accordion-header px-0 row  justify-content-center" :id="'heading'+id">
                                    <div class="col-12 d-flex justify-content-between align-items-center px-4">
                                        <span>
                                            <h3>{{ id+1 }}</h3>
                                        </span>
                                        <div class="d-flex flex-column justify-content-center align-items-start ps-2 w-100">
                                            <h3 v-show="!question.title">{{ $t('message.simpleQuestion')+' '+(id+1) }}</h3>
                                            <h3 v-show="question.title">{{ question.title }}</h3>
                                        </div>
                                        <button type="button" class="eliminar" @click="deleteQuestion(question, actualActivity.questions, id)" v-if="question.deletable">
                                            <img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="">
                                        </button>
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+id" aria-expanded="false" aria-controls="collapse">
                                            
                                        </button>
                                    </div>
                                    <div :id="'collapse'+id" class="col-12 accordion-collapse collapse px-1" :aria-labelledby="'heading'+id" data-bs-parent="#accordionExample">
                                        <div class="accordion-body row justify-content-center mt-3">
                                            <div class="col-12 d-flex justify-content-center align-items-center question">
                                                <div class="row  row__width justify-content-end" :class="question.enabled == true ? '' : 'disabled'">
                                                    <span class="col-1">
                                                        <h3>{{ id+1 }}</h3>
                                                    </span>
                                                    <div class="col-10 col-lg d-flex flex-column justify-content-center align-items-starts pe-0">
                                                        <input type="text" :placeholder="$t('message.questionPlaceholder')" id="" v-model="question.title" @input="activeButton(question)">
                                                    </div>
                                                    <div class="col-2 col-lg-1 ms-2 ms-lg-3 d-flex justify-content-between align-items-center switch px-0" v-if="!question.deletable">
                                                        <div class="form-check form-switch d-flex justify-content-start align-items-center">
                                                            <input class="form-check-input" type="checkbox" role="switch" :id="'disableQuestion'+id" v-model="question.enabled">
                                                            <label class="form-check-label" :for="'disableQuestion'+id"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-for="(answer, index) in question.answers" class="col-12 d-flex flex-column justify-content-center align-items-center body mt-3">
                                                <div class="row row__width justify-content-center border-bottom-questions">
                                                    <div class="col-12 col-xl d-flex justify-content-center align-items-center px-0">
                                                        <div class="row row__width justify-content-between justify-content-lg-center justify-content-xl-between align-items-center" :class="answer.enabled == true ? '' : 'disabled'">
                                                            <span class="me-lg-2 me-2" >
                                                                <h3 class="upperCase">{{ indexToLetter(index) }}</h3>
                                                            </span>
                                                            <div class="col-10 col-lg-5 col-xl d-flex flex-column justify-content-center align-items-start px-0 ps-1 ps-lg-0">
                                                                <input type="text" :placeholder="$t('message.answerNoPlaceholder')+(index+1)" :id="'id'+index" v-model="answer.answer" @input="activeButton(question)">
                                                            </div>
                                                            <div class="col-6 col-lg-2 col-xl-2 d-flex flex-column justify-content-center align-items-center pe-0 my-3 my-lg-0">
                                                                <div class="row separador row__width tipo__reunion">
                                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                                        <input type="checkbox" :id="'incorrect2'+id+index" v-model="answer.incorrect" @input="activeButton(question)">
                                                                        <label :for="'incorrect2'+id+index" class="row">
                                                                            <div class="col-6 d-flex justify-content-center align-items-center correct">
                                                                                <i></i>
                                                                            </div>
                                                                            <div class="col-6 d-flex justify-content-center align-items-center px-0 incorrect">
                                                                                <i></i>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 col-lg-1 d-flex justify-content-center align-items-center switch px-0" v-if="!answer.deletable">
                                                                <div class="form-check form-switch d-flex justify-content-start align-items-center">
                                                                    <input class="form-check-input" type="checkbox" role="switch" :id="'disable'+id+index" v-model="answer.enabled">
                                                                    <label class="form-check-label" :for="'disable'+id+index"></label>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 max-width d-flex justify-content-end align-items-center px-0 my-2 my-lg-0" :class="answer.deletable ? 'col-lg-2' : 'col-lg-1'">
                                                                <button type="button" @click="deleteResponse(answer.id, question.answers, index)" v-if="question.answers.length > 2 && answer.deletable"><img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt=""></button>
                                                                <button type="button" @click="addAnswer(question.answers)" v-if="index == question.answers.length-1"><img src="../../../img/panel-icons/mas.svg" alt=""></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col d-flex flex-column justify-content-start align-items-start px-3 mt-3 checkboxes">
                                                <div class="row justify-content-center w-100">
                                                    <div class="col-12 d-flex justify-content-center align-items-center">
                                                        <div class="row justify-content-center justify-content-lg-start row__width">
                                                            <div class="col-12 d-flex justify-content-start align-items-center my-3 px-0">
                                                                <p class="sin-padding p-0">Cantidad de respuestas a mostrar en el cuestionario</p>
                                                            </div>
                                                            <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center px-0" :id="'respShown-input-empty'+id">
                                                                <input type="number" :id="'id2'+id" v-model="question.respShown" @input="activeButton(question)">
                                                            </div>
                                                            <div class="col-12 d-flex justify-content-start align-items-center error d-none mt-3" :id="'respShown-empty'+id">
                                                                <p>Por favor, complete el numero de preguntas a mostrar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-flex justify-content-start align-items-center">
                                                        <input type="checkbox" :id="'personalized'+id" class="d-none" v-model="question.hasImage" @input="activeButton(question)">
                                                        <label :for="'personalized'+id" class="d-flex justify-content-start align-items-center my-3">
                                                            <p>Personalizada</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- PERSONALIZADA -->
                                            <div class="col-12 d-flex justify-content-center align-items-center px-3 mt-2 mt-lg-3" v-if="question.hasImage">
                                                <div class="row justify-content-between row__width">
                                                    <div class="col-12 d-flex justify-content-start align-items-center px-0 mb-3">
                                                        <h3>{{ $t('message.uploadAFile') }}</h3>
                                                    </div>
                                                    <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center input__file mt-4 px-0 ps-lg-0" v-if="question.hasImage">
                                                        <input type="file" name="" ref="question.image" :id="'file-image-question'+id" accept="image/*" @input="activeButton(question)" @change="handleFileChange($event, id); handleFileUpload($event, id)">
                                                        
                                                        <label :for="'file-image-question'+id" v-if="cacheImage[id]">
                                                            <img :src="cacheImage[id]" alt="" class="img">
                                                        </label>
                                                        <label :for="'file-image-question'+id" v-else-if="question.imageUrl">
                                                            <img :src="question.imageUrl" alt="" class="img">
                                                        </label>
                                                        <label :for="'file-image-question'+id" v-else>
                                                            <h4>{{ $t('message.dragFiles') }}</h4>
                                                            <span><img src="../../../img/panel-icons/perfil-mas.svg" alt="">{{ $t('message.selectFile') }}</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-start px-0 ps-lg-4 pe-0 mt-4">
                                                        <h5>{{ $t('message.finishesQuiz') }}</h5>
                                                        <h6>{{ $t('message.maximumSizeV2') }}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-between align-items-end px-0">
                                            <div>
                                                <p class="error ms-4" v-if="hasMoreThanOne(question)">Debe haber una respuesta correcta</p>
                                                <p class="error ms-4" v-if="hasMoreThanShown(question)">Debe haber mas respuestas</p>
                                                <p class="error ms-4" v-if="hasMoreThanOneIncorrect(question)">Debe haber solo una respuesta correcta</p>
                                            </div>
                                            <div>
                                                <button type="button" class="button" @click="editQuestion(question, actualActivity.questions, id)" v-if="question.isActive">GUARDAR PREGUNTA</button>
                                                <h3 class="saved" v-else>Guardado!</h3>
                                            </div>
                                        </div> 
                                    </div>
                                </h2>
                            </div>
                            <!--- TIPO DE PREGUNTA MULTIPLE --->
                            <div class="accordion-item w-100" v-if="question.type == 2">
                                <h2 class="accordion-header px-0 row justify-content-center" :id="'heading'+id">
                                    <div class="col-12 d-flex justify-content-between align-items-center px-4">
                                        <span>
                                            <h3>{{ id+1 }}</h3>
                                        </span>
                                        <div class=" d-flex flex-column justify-content-start align-items-center ps-2 w-100">
                                            <h3 v-show="question.title == ''">{{ 'Pregunta multiple ' +(id+1) }}</h3>
                                            <h3 v-show="question.title != ''">{{ question.title }}</h3>
                                        </div>
                                        <button type="button" class="eliminar" @click="deleteQuestion(question, actualActivity.questions, id)" v-if="question.deletable">
                                            <img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="">
                                        </button>
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+id" aria-expanded="false" aria-controls="collapse"></button>
                                    </div>
                                    <div :id="'collapse'+id" class="col-12 accordion-collapse collapse px-1" :aria-labelledby="'heading'+id" data-bs-parent="#accordionExample">
                                        <div class="accordion-body row justify-content-center mt-3">
                                            <div class="col-12 d-flex justify-content-center align-items-center question">
                                                <div class="row  row__width justify-content-end" :class="question.enabled == true ? '' : 'disabled'">
                                                    <span class="col-1">
                                                        <h3>{{ id+1 }}</h3>
                                                    </span>
                                                    <div class="col d-flex flex-column justify-content-center align-items-starts pe-0">
                                                        <input type="text" :placeholder="$t('message.questionPlaceholder')" id="" v-model="question.title" @input="activeButton(question)">
                                                    </div>
                                                    <div class="col-2 col-lg-1 ms-2 ms-lg-3 d-flex justify-content-between align-items-center switch px-0" v-if="!question.deletable">
                                                        <div class="form-check form-switch d-flex justify-content-start align-items-center">
                                                            <input class="form-check-input" type="checkbox" role="switch" :id="'disableMultiple'+id" v-model="question.enabled">
                                                            <label class="form-check-label" :for="'disableMultiple'+id"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-for="(answer, index) in question.answers" class="col-12 d-flex flex-column justify-content-center align-items-center body mt-3">
                                                <div class="row row__width justify-content-start justify-content-lg-center">
                                                    <div class="col-12 col-xl d-flex justify-content-center align-items-center px-0">
                                                        <div class="row row__width justify-content-between justify-content-lg-center justify-content-xl-between align-items-center" :class="answer.enabled == true ? '' : 'disabled'">
                                                            <span class="me-lg-2" >
                                                                <h3 class="upperCase">{{ indexToLetter(index) }}</h3>
                                                            </span>
                                                            <div class="col-10 col-lg-9 col-xl d-flex flex-column justify-content-center align-items-start px-0 ps-1 ps-lg-0">
                                                                <input type="text" :placeholder="$t('message.answerPlaceholder')+(index+1)" :id="'id'+index" v-model="answer.answer" @input="activeButton(question)">
                                                            </div>
                                                            <div class="col-6 col-xl-2 d-flex flex-column justify-content-center align-items-center pe-0 my-3 my-lg-0">
                                                                <div class="row separador row__width tipo__reunion">
                                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                                        <input type="checkbox" :id="'incorrect'+id+index" v-model="answer.incorrect" @input="activeButton(question)">
                                                                        <label :for="'incorrect'+id+index" class="row">
                                                                            <div class="col-6 d-flex justify-content-center align-items-center correct">
                                                                                <i></i>
                                                                            </div>
                                                                            <div class="col-6 d-flex justify-content-center align-items-center px-0 incorrect">
                                                                                <i></i>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 col-xl-1 d-flex justify-content-between align-items-center switch px-0" v-if="!answer.deletable">
                                                                <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                                                                    <input class="form-check-input" type="checkbox" role="switch" :id="'disableAnswer'+id+index" v-model="answer.enabled">
                                                                    <label class="form-check-label" :for="'disableAnswer'+id+index"></label>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 max-width d-flex justify-content-end align-items-center px-0 my-3 my-lg-0" :class="answer.deletable ? 'col-xl-2' : 'col-xl-1'">
                                                                <button type="button" @click="deleteResponse(answer.id, question.answers, index)" v-if="question.answers.length > 2 && answer.deletable"><img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt=""></button>
                                                                <button type="button" @click="addAnswer(question.answers)" v-if="index == question.answers.length-1"><img src="../../../img/panel-icons/mas.svg" alt=""></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col d-flex flex-column justify-content-start align-items-start px-3 mt-3 checkboxes">
                                                <div class="row justify-content-center w-100">
                                                    <div class="col-12 d-flex justify-content-center align-items-center">
                                                        <div class="row justify-content-center justify-content-lg-start row__width">
                                                            <div class="col-12 d-flex justify-content-start align-items-center my-3 px-0">
                                                                <p class="sin-padding p-0">Cantidad de respuestas a mostrar en el cuestionario</p>
                                                            </div>
                                                            <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center px-0" :id="'respShown-input-empty'+id">
                                                                <input type="number" :id="'id2'+id" v-model="question.respShown" @input="activeButton(question)">
                                                            </div>
                                                            <div class="col-12 d-flex justify-content-start align-items-center error d-none mt-3" :id="'respShown-empty'+id">
                                                                <p>Por favor, complete el numero de respuestas a mostrar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-flex justify-content-center align-items-center">
                                                        <div class="row justify-content-center justify-content-lg-start row__width">
                                                            <div class="col-12 d-flex justify-content-start align-items-center my-3 px-0">
                                                                <p class="sin-padding p-0">Cantidad de respuestas correctas a mostrar en el cuestionario</p>
                                                            </div>
                                                            <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center px-0" :id="'correctShown-input-empty'+id">
                                                                <input type="number" :id="'id3'+id" v-model="question.correctShown" @input="activeButton(question)">
                                                            </div>
                                                            <div class="col-12 d-flex justify-content-start align-items-center error d-none mt-3" :id="'correctShown-empty'+id">
                                                                <p>Por favor, complete el numero de respuestas correctas a mostrar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-flex justify-content-start align-items-center">
                                                        <input type="checkbox" :id="'personalized'+id" class="d-none" v-model="question.hasImage" @input="activeButton(question)">
                                                        <label :for="'personalized'+id" class="d-flex justify-content-start align-items-center my-3">
                                                            <p>Personalizada</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col d-flex flex-column justify-content-start align-items-start px-3 mt-3 checkboxes">
                                                <div class="d-flex flex-column justify-content-start align-items-start mt-3">
                                                    <p class="sin-padding">Cantidad de respuestas a mostrar en el cuestionario</p>
                                                    <div class="d-flex flex-column justify-content-start align-items-start mt-3" :id="'respShown-input-empty'+id">
                                                        <input type="number" :id="'id2'+id" v-model="question.respShown" @input="activeButton(question)">
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 d-flex justify-content-start align-items-center error d-none" :id="'respShown-empty'+id">
                                                            <p>Por favor, complete el numero de preguntas a mostrar</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column justify-content-start align-items-start mt-3">
                                                    <p class="sin-padding">Cantidad de respuestas correctas a mostrar en el cuestionario</p>
                                                    <div class="d-flex flex-column justify-content-start align-items-start mt-3" :id="'correctShown-input-empty'+id">
                                                        <input type="number" :id="'id3'+id" v-model="question.correctShown" @input="activeButton(question)">
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 d-flex justify-content-start align-items-center error d-none" :id="'correctShown-empty'+id">
                                                            <p>Por favor, complete el numero de respuestas correctas a mostrar</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="checkbox" :id="'personalized'+id" class="d-none" v-model="question.hasImage" @input="activeButton(question)">
                                                <label :for="'personalized'+id" class="d-flex justify-content-start align-items-center my-3">
                                                    <p>Personalizada</p>
                                                </label>
                                            </div> -->
                                            <!-- PERSONALIZADA -->
                                            <div class="col-12 d-flex justify-content-center align-items-center px-3 mt-2 mt-lg-5" v-if="question.hasImage">
                                                <div class="row justify-content-between row__width">
                                                    <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                        <h3>{{ $t('message.uploadAFile') }}</h3>
                                                    </div>
                                                    <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center input__file mt-4 px-0 ps-lg-0" v-if="question.hasImage">
                                                        <input type="file" name="" ref="question.image" :id="'file-image-question'+id" accept="image/*" @input="activeButton(question)" @change="handleFileChange($event, id); handleFileUpload($event, id)">
                                                        <label :for="'file-image-question'+id" v-if="cacheImage[id]">
                                                            <img :src="cacheImage[id]" alt="" class="img">
                                                        </label>
                                                        <label :for="'file-image-question'+id" v-else-if="question.imageUrl">
                                                            <img :src="question.imageUrl" alt="" class="img">
                                                        </label>
                                                        <label :for="'file-image-question'+id" v-else>
                                                            <h4>{{ $t('message.dragFiles') }}</h4>
                                                            <span><img src="../../../img/panel-icons/perfil-mas.svg" alt="">{{ $t('message.selectFile') }}</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-start px-0 ps-lg-4 pe-0 mt-4">
                                                        <h5>{{ $t('message.finishesQuiz') }}</h5>
                                                        <h6>{{ $t('message.maximumSizeV2') }}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-between align-items-end px-0">
                                            <div>
                                                <p class="error ms-3" v-if="hasMoreThanOne(question)">Debe haber una respuesta correcta</p>
                                                <p class="error ms-3" v-if="hasOneIncorrect(question)">Debe haber una respuesta incorrecta</p>
                                                <p class="error ms-3" v-if="hasMoreThanOneCorrect(question)">Debe haber más respuestas incorrecta</p>
                                            </div>
                                            <div>
                                                <button type="button" class="button" @click="editQuestion(question, actualActivity.questions, id)" v-if="question.isActive">GUARDAR PREGUNTA</button>
                                                <h3 class="saved" v-else>Guardado!</h3>
                                            </div>
                                        </div>
                                    </div>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center row__width error" v-if="actualActivity.questions && actualActivity.questions.length == 0">
                <p>{{ $t('message.emptyQuetionOrAnswer') }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, provide, ref, watch, inject } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../stores/user";
import { useLanguageStore } from "../../../stores/langs"

const users = useUsersStore();
const { access } = storeToRefs(users);
const { url, validate } = users;

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

const urlCreateQuestion = '/api/product/creating/module/lesson/questions/configure'
const urlEditQuestion = '/api/product/creating/module/lesson/questions/configure'
const urlDeleteQuestion = '/api/product/creating/module/lesson/questions/delete/'
const urlDeleteResponse = '/api/product/creating/module/lesson/response/delete/'

let actualActivity = inject('actualActivity')
let product = inject('product')

let owner_id = inject('owner_id')
let product_id = inject('product_id')

const cacheImage = ref([])

const activeButton = (question) => {
    question.isActive = true
}

const handleFileUpload = (event, id) => {
    const file = event.target.files[0];
    //console.log(id)
    if (file) {
        cacheImage.value[id] = URL.createObjectURL(file);
        //console.log(cacheImage.value)
    } else {
        cacheImage.value.splice(id, 1);
    }
}

const hasMoreThanOne = (q) => {
    if(q.type == 1){
        let count = 0
        q.answers.forEach(element => {
            if(!element.incorrect){
                count++
            }
        })
        if(count >= 1){
            return false
        }
        else{
            return true
        }
    }
    else if(q.type == 2){
        let count = 0
        q.answers.forEach(element => {
            if(!element.incorrect){
                count++
            }
        })
        if(count >= 1){
            return false
        }
        else{
            return true
        }
    }
}
const hasMoreThanShown = (q) => {
    if(q.type == 1){
        if(q.answers.length >= q.respShown){
            return false
        }
        else{
            return true
        }
    }
}
const hasOneIncorrect = (q) => {
    let count = 0
    q.answers.forEach(element => {
        if(element.incorrect){
            count++
        }
    })
    if(count >= 1){
        return false
    }
    else{
        return true
    }
}
const hasMoreThanOneIncorrect = (q) => {
    let count = 0
    q.answers.forEach(element => {
        if(element.incorrect){
            count++
        }
    })
    if(count >= q.answers.length - 1){
        return false
    }
    else{
        return true
    }
}

const hasMoreThanOneCorrect = (q) => {
    let count = 0
    q.answers.forEach(element => {
        if(element.incorrect){
            count++
        }
    })
    if(count >= q.respShown - q.correctShown){
        return false
    }
    else{
        return true
    }
}

const addAnswer = (questions) => {
    questions.push({answer: '', id: null, transId: null, incorrect: false, enabled: true, deletable: true})
}

const deleteAnswer= (array, id) => {
    array.splice(id, 1);
}

const indexToLetter = (id) => {
  return String.fromCharCode(97 + id);
}

const handleFileChange = (event, id) => {
  //console.log('holi')
  const file = event.target.files[0];
  //console.log(file)
  actualActivity.value.questions[id].image = file;
  //console.log(actualActivity.value.questions[id].image)
};

const createQuestion = async(type) => {
    let id = product.value.id
    let userId = product.value.ownerId
    let quizId = actualActivity.value.quizId
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    console.log(actualActivity.value)
    try {
        await axios.post(url+urlCreateQuestion, {id: id, quizId: quizId, type: type, userId: userId, enabled: true, answers: [], owner_id: owner_id.value, product_id: product_id.value}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                if(result.data.type == 1){
                    actualActivity.value.questions.push({
                        quizId: result.data.quizId,
                        transId: result.data.transId,
                        questionId: result.data.questionId,
                        userId: Number(result.data.userId),
                        type: result.data.type,
                        title: '',
                        description: '',
                        answers: [
                            {answer: '', id: null, transId: null, incorrect: false, enabled: true, deletable: true},
                            {answer: '', id: null, transId: null, incorrect: false, enabled: true, deletable: true}
                        ],
                        hasImage: result.data.hasImage,
                        imageUrl: result.data.imageUrl,
                        respShown: result.data.respShown,
                        correctShown: 1,
                        enabled: true,
                        deletable: true,
                        isActive: true
                    })
                    console.log(actualActivity.value)
                }
                else if(result.data.type == 2){
                    actualActivity.value.questions.push({
                        quizId: result.data.quizId,
                        transId: result.data.transId,
                        questionId: result.data.questionId,
                        userId: Number(result.data.userId),
                        type: result.data.type,
                        title: '',
                        description: '',
                        answers: [
                            {answer: '', id: null, transId: null, incorrect: false, enabled: true, deletable: true},
                            {answer: '', id: null, transId: null, incorrect: false, enabled: true, deletable: true}
                        ],
                        hasImage: result.data.hasImage,
                        imageUrl: result.data.imageUrl,
                        respShown: result.data.respShown,
                        correctShown: result.data.correctShown,
                        enabled: true,
                        deletable: true,
                        isActive: true
                    })
                }
            }
        })
    } catch (error) {
        console.log(error)
    }
}

const editQuestion = async(question, questions, id) => {
    let respShown = document.getElementById('respShown-empty'+id)
    let respShownInput = document.getElementById('respShown-input-empty'+id)
    let correctShown = document.getElementById('correctShown-empty'+id)
    let correctShownInput = document.getElementById('correctShown-input-empty'+id)
    if(question.type == 1 && question.respShown >= 2 || question.type == 2 && question.respShown >= 2 && question.correctShown >= 1){
        if(question.type == 1){
            respShown.classList.add('d-none')
            respShownInput.classList.remove('error-input')
        }
        else if(question.type == 2){
            respShown.classList.add('d-none')
            respShownInput.classList.remove('error-input')
            correctShown.classList.add('d-none')
            correctShownInput.classList.remove('error-input')
        }
        console.log(question)
        console.log(questions)
        let userId = product.value.ownerId
        let quizId = actualActivity.value.quizId
        const formData = new FormData()
        formData.append('id', product.value.id)
        formData.append('quizId', quizId)
        formData.append('transId', question.transId)
        formData.append('questionId', question.questionId)
        formData.append('respShown', question.respShown)
        formData.append('correctShown', question.correctShown)
        formData.append('userId', userId)
        formData.append('type', question.type)
        formData.append('enabled', question.enabled)
        formData.append('deletable', question.deletable)
        console.log(question.hasImage)
        console.log(question.image)
        if(question.image != undefined){
            formData.append('image', question.image)
        }
        formData.append('id', product.value.id)
        formData.append('title', question.title)
        formData.append('description', question.description)
        formData.append('lang', 'ES')
        formData.append('owner_id', owner_id.value)
        formData.append('product_id', product_id.value)
        for (var i = 0; i < question.answers.length; i++) {
            formData.append('answers[]', JSON.stringify(question.answers[i]));
        }
        const token = JSON.parse(localStorage.getItem('token'))
        const headers = ref({'Authorization': `Bearer ${token}`})
        try {
            await axios.post(url+urlEditQuestion, formData, {headers: headers.value}).then((result) => {
                console.log(result)
                let i = actualActivity.value.questions[id]
                i.transId = result.data.transId
                i.questionId = result.data.questionId
                i.type = result.data.type
                i.answers = result.data.answers
                i.hasImage = result.data.hasImage
                i.imageUrl = result.data.imageUrl
                i.isActive = false
            })
        } catch (error) {
            console.log(error)
            question.isActive = true
        }
    }
    else {
        if(question.type == 1){
            if(question.respShown < 2 || !question.respShown){
                respShown.classList.remove('d-none')
                respShownInput.classList.add('error-input')
            } else{
                respShown.classList.add('d-none')
                respShownInput.classList.remove('error-input')
            }
        }
        if(question.type == 2){
            console.log(question.type)
            if(question.respShown < 2 || !question.respShown){
                respShown.classList.remove('d-none')
                respShownInput.classList.add('error-input')
            }
            else {
                respShown.classList.add('d-none')
                respShownInput.classList.remove('error-input')
            }
            if(question.correctShown < 1 || !question.correctShown){
                correctShown.classList.remove('d-none')
                correctShownInput.classList.add('error-input')
            }
            else {
                correctShown.classList.add('d-none')
                correctShownInput.classList.remove('error-input')
            }
        }
    }
}

const deleteQuestion = async(question, array, id) => {
    let pId = product.value.id
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.delete(url+urlDeleteQuestion+question.questionId, {data: {userId: question.userId, id: pId}, headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 200){
                deleteAnswer(array, id)
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const deleteResponse = async(answerId, array, id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.delete(url+urlDeleteResponse+answerId, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 200){
                deleteAnswer(array, id)
            }
        })
    } catch (error) {
        console.error(error)
    }
}

</script>

<style lang="scss" scoped>
h2{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 22px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%; 
}
h3{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 18px;
    text-align: left;
    width: 100%;
}
h4{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
p{
    color: var(--colorSecondary);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
}
.checkboxes{
    label{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        &:before{
            content: '';
            border: 2px solid #E4E4E4;
            border-radius: 5px;
            height: 19px;
            width: 19px;
            margin-top: 4px;
        }
    }
    input:checked + label{
        border-color: var(--colorPrimary);
        &:before{
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            background-image: url('../../../img/subir-productos-icons/cursos-icons/tick-check.svg');
            height: 19px;
            width: 19px;
        }
    }
    p{
        font-weight: 500;
        font-size: 16px;
        padding-left: 10px;
        text-align: left;
    }
    h6{
        color: #2E4765;
        font-size: 14px;
        font-weight: 300;
        text-align: left;
        padding-left: 10px;
    }
}
.agregar-cursos-btn{
    box-shadow: 0 3px 6px #00000016;
    border-radius: 43px;
    height: 60px;
    margin: 20px 0;
    border: 0;
    background-color: var(--bgPrincipal);
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    position: relative;
    p{
        padding-left: 5px;
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 15px;
    }
}

.collapses{
    h3{
        margin-bottom: 0px !important;
        font-size: 16px;
        text-align: left;
        overflow: hidden;
        word-break: break-all;
        max-width: 100%;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            margin: 15px 0px;
            border: 0;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-button{
            border-radius: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: var(--bgPrincipal);
            width: auto;
            &:focus{
                border-color: inherit;
                box-shadow: none;
            }
            
        }
        .accordion-button:not(.collapsed){
            background-color: inherit;
            box-shadow: none;
        }
        .accordion-button::after{
            position: absolute;
            right: 2.5%;
            background-image: url('../../../img/subir-productos-icons/arrow-gris-abajo.svg');
            background-size: 100%;
            background-position: center;
            @media (max-width: 991px) {
                right: 5%;
            }
        }
        .accordion-header{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            padding: 10px 15px;

            span{
                border: 2px solid #E9E3E3;
                min-height: 40px;
                min-width: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                h3{
                    text-align: center;
                    overflow: hidden;
                    word-break: break-all;
                }
            }
            .eliminar{
                border: 2px solid #E9E3E3;
                min-height: 40px;
                min-width: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
            }
            
            .accordion-body{
                input,select{
                    width: 100%;
                    border: 2px solid #E9E3E3;
                    border-radius: 10px;
                    background-color: #FCFCFC;
                    height: 50px;
                    padding-left: 15px;
                    outline: 0;
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    -webkit-appearance: none;
                    &:focus, &:hover{
                        border: 2px solid #1679FC;
                    }
                    &::placeholder{
                        color: #697891;
                    }
                }
                span{
                    border: 2px solid #E9E3E3;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        text-align: center;
                    }
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    min-height: 40px;
                    min-width: 40px;
                    margin-left: 10px;
                }
                h3{
                    font-size: 16px;
                }
                .disabled{
                    input[type=text]{
                        pointer-events: none;
                        background-color: #f5f5f5;
                    }
                }
                .body{
                    
                    span{
                        border: 2px solid #E9E3E3;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h3{
                            text-align: center;
                        }
                    }
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        margin-left: 5px;
                    }
                    .disabled{
                        input[type=text]{
                            pointer-events: none;
                            background-color: #f5f5f5;
                        }
                        .tipo__reunion{
                            label{
                                background-color: #f5f5f5;
                            }
                            input{
                                pointer-events: none;
                            }
                        }
                    }
                }
                .width{
                    width: 97%;
                }
                .input__file{
                    label{
                        border: 2px dashed #E4E4E4;
                        border-radius: 15px;    
                        background-color: #FAFAFA;
                        height: 137px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    input{
                        display: none;
                    }
                    h4{
                        font-weight: 500;
                        color: #697891;
                        font-size: 14px;
                        text-align: center;
                        padding: 0 0 20px 0;
                    }
                    h5{
                        font-weight: 400;
                        color: #B8C1DB;
                        font-size: 14px;
                        text-align: center;
                        padding: 20px 0;
                    }
                    span{
                        cursor: pointer;
                        border: 2px dashed var(--colorPrimary);
                        height: 50px;
                        width: 80%;
                        color: var(--colorPrimary);
                        font-size: 16px;
                        font-weight: 700;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 28px;
                        img{
                            margin-right: 10px;
                        }
                    }
                    @media (max-width: 991px) {
                        span{
                            font-size: 14px;
                            img{
                                max-height: 15px;
                            }
                        }
                    }
                }
                h5{
                    color: #697891;
                    font-size: 16px;
                    font-weight: 400;
                    text-align: left;
                }
                h6{
                    color: #B8C1DB;
                    font-size: 16px;
                    font-weight: 400;
                    text-align: left;
                    padding: 7px 0px;
                }
                .tipo__reunion{
                    label{
                        height: 40px;
                        width: 100%;
                        border-radius: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        margin-right: 5px;
                        cursor: pointer;
                        background-color: #fff;
                        border: 2px solid #69789118;
                        &::before{
                            content: '';
                            position: absolute;
                            height: 100%;
                            width: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 2px solid #38E6A7;
                            border-radius:  28px;
                            left: 0%;
                            transition: all 0.5s;
                        }
                        p{
                            font-weight: 500;
                            font-size: 14px;
                            color: var(--bgPrincipal);
                            z-index: 1;
                            transition: all 0.5s;
                            &:first-child{
                                margin-right: 0%;
                            }
                            &:last-child{
                                margin-left: 0%;
                            }
                        }
                        .left{
                            color: var(--bgPrincipal);
                            font-weight: 600;
                        }
                        .right{
                            color: var(--colorSecondary);
                        }
                        i{
                            width: 20px;
                            height: 20px;
                            display: block;
                            background-repeat: no-repeat;
                            background-size: 100%;
                            background-position: center;
                        }
                        .correct i{
                            background-image: url('../../../img/subir-productos-icons/correct.svg');
                        }
                        .incorrect i{
                            background-image: url('../../../img/subir-productos-icons/incorrect.svg');
                        }
                    }
                    input{
                        display: none;
                    }
                    input:checked + label{
                        &::before{
                            left: 49%;
                            width: 50%;
                            border: 2px solid #FF576E;
                        }
                        p{
                            color: var(--bgPrincipal);
                            font-weight: 600;
                            
                        }
                        .left{
                            color: var(--colorSecondary);
                            font-weight: 500;
                        }
                    }
                    @media (min-width: 992px) {
                        label{
                            .right{
                                color: var(--colorSecondary);
                            }
                        }
                        input:checked + label{
                            .left{
                                color: var(--colorSecondary);
                            }
                        }
                    }
                }
                .switch{
                    .form-switch .form-check-input{
                        width: 48px;
                        height: 23px;
                        background-color: #E4E4E4;
                        background-image: url('../../../img/panel-icons/check-icon.svg');
                        background-size: 40%;
                        border: 0;
                        background-position-x: 2px;
                    }
                    .form-switch .form-check-input:focus{
                        outline: 0;
                        box-shadow: none;
                        border: 0;
                        background-image: url('../../../img/panel-icons/check-icon.svg');
                        background-size: 40%;
                    }
                    .form-switch .form-check-input:checked{
                        background-color: var(--colorPrimary);
                        background-position-x: 27px;
                    }
                }
                .max-width{
                   @media (min-width: 1300px) {
                        max-width: 12%;
                   }
                }
                @media (min-width: 992px) {
                    padding: 10px 5px;
                    .question{
                        padding: 0 25px;
                    }
                }
                @media (min-width: 1024px) {
                    padding: 10px 15px;
                    .question{
                        padding: 0 15px;
                    }
                }
                @media (min-width: 1050px) {
                    padding: 10px 30px;
                    .question{
                        padding: 0;
                    }
                }
            }
        }
    }
}
.ofertas{
    border: 1px solid #E4E4E4;
    background-color: var(--bgPrincipal);
    border-radius: 15px;
    height: 110px;
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
    }
    h5{
        color: #2E4765;
        font-weight: 300;
        font-size: 14px;
    }
    .eliminar{
        border-left: 2px solid #E4E4E4;
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-left: 10px;
        }
    }
    .ejemplo{
        border-radius: 5px;
        height: 83px;
        img{
            max-height: 100%;
            max-width: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    
}
.dropdown{
    width: 100%;
    box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.15);
    border-radius: 10px;
    button{
        width: 100%;
        background-color: var(--bgPrincipal);
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        border: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-radius: 10px;
        padding: 0 20px;
        h3{
            padding: 20px 0;
        }
        .dropdown-menu{
            background-color: var(--bgPrincipal);
            width: 100%;
            border: 0;
            padding: 5px 20px 0 20px;
            height: auto;
            border-radius: 0 0 10px 10px ;
            a:hover, a:focus{
                background-color: inherit;
            }
            a{
                padding: 0 0 10px 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: #2E4765;
                font-size: 15px;
                font-weight: 400;
            }
            span{
                height: 40px;
                width: 40px;
                border: 2px solid #E4E4E4;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
            }
            li{
                width: 100%;
            }
        }
        &:focus{
            background-color: inherit;
        }
    }
    .dropdown-toggle::after{
        display: none;
    }
    .arrow{
        width: 25px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease 0.2s;
        background-size: 75%;
        background-position: center;
    }
}
.dropdown-toggle.show{
    .dropdown-menu{
        box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.15);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:after{
            content: '';
            position: absolute;
            background-color: var(--bgPrincipal);
            width: 100%;
            height: 10px;
            top: -7px;
        }
    }
    .arrow{
        transform: rotate(180deg);
        transition: all ease 0.2s;
    }
}
.upperCase {
    text-transform: uppercase;
}
// .border-bottom-questions{
//     border-bottom: 1px solid #00000029;
//     margin: 10px 0;
// }
.button{
    background-color: transparent;
    border-radius: 24px;
    height: 50px;
    border: 2px solid var(--colorPrimary);
    color: var(--colorPrimary);
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    cursor: pointer;
}
.img{
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    max-height: 150px;
    border-radius: 20px;
}
.plyr{
    min-width: 100px !important;
}

.error-color{
    color: #FF6A6A !important;
}
.error{
    border: 2px solid #F6BEBE;
    background-color: #FCEEEE;
    min-height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    padding: 0 10px;
    p{
        color: #FF6A6A;
        font-size: 16px;
        font-weight: 400;
    }
}
.error-input{
    input{
        border-color: #F6BEBE !important;
    }
}
</style>