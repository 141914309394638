<template>
    <div class="row justify-content-center justify-content-lg-start my-3 px-2 px-lg-3 scroll-2" v-if="idUser.team == 3">
        <div class="col-12 d-flex flex-column justify-content-start align-items-center px-0" v-for="(item, id) in professionalProducts">
            <div class="row justify-content-center row__width ps-3 slide">
                <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center px-0 caption" v-if="item.presentations">
                    <img v-if="item.image" :src="item.image" alt="">
                </div>
                <div class="col-9 col-lg-6 d-flex flex-column justify-content-center align-items-start">
                    <h3>{{ item.name }}</h3>
                    <h4>{{ item.description }}</h4>
                </div>
                <div class="col-12 col-lg-2 d-flex flex-column justify-content-center align-items-start px-0 mt-3 mt-lg-0">
                    <h3>{{ $t('message.status') }}</h3>
                    <h4 class="pb-4 pass">Aprobado</h4>
                </div>
                <div class="col-12 col-lg d-flex justify-content-center align-items-center border-left px-0 my-3 my-lg">
                    <button @click="productSheet = true, qualifyContent = false, fetchProfProduct(item.id), fetchTasksProf(item.id), fetchFPProf(item.id)" class="btn__primario mirar__ahora">    
                        Gestionar
                    </button>
                    <!--- :to="'/editar-curso/1/'+item.id" --->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../stores/user"
import { useLanguageStore } from "../../stores/langs"
import { onBeforeMount, ref, provide, watch, inject, onMounted } from 'vue';
import axios from 'axios';

const {locale} = useI18n({ useScope: 'global' })

const users = useUsersStore();
const { access, idUser } = storeToRefs(users);
const { url, validate } = users;

const langs = useLanguageStore();
const {listLangs} = storeToRefs(langs);

const lang = 'ES'

const professionalProducts = inject('professionalProducts')
const productSheet = inject('productSheet')
const fetchProfProduct = inject('fetchProfProduct')
const fetchTasksProf = inject('fetchTasksProf')
const fetchFPProf = inject('fetchFPProf')
let qualifyContent = inject('qualifyContent')
</script>

<style lang="scss" scoped>
.scroll-2{
    max-height: 80vh;
    overflow-y: scroll !important;
    overflow-x: hidden;
    &::-webkit-scrollbar{
        -webkit-appearance: none;
        width: 4px;
        height: 4px;
        background-color: #29292b1e;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #29292b1e;
    }
}
.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    min-height: auto;
    padding: 10px 0;
    margin-bottom: 20px;
    .see-more{
        font-size: 14px;
        color: var(--colorPrimary);
        font-weight: 500;
        text-decoration: underline;
    }
    .border-left{
        border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
        @media (max-width: 991px) {
            border: 0;
        }
    }
    h3{
        color: #0E1133;
        font-weight: 700;
        font-size: 16px;
        text-align: left;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
        text-align: left;
        padding-top: 3px;
        max-height: 60px;
        max-width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        
    }
    .acciones{
        color: #B8C1DB;
        font-size: 14px;
        font-weight: 500;
    }
    .dropdown-toggle{
        color: var(--colorSecondary);
        font-weight: 500;
        font-size: 13px;
        text-decoration: underline;
        padding: 0;
        img{
            margin-left: 5px;
        }
        &::after{
            display: none;
        }
        @media (min-width: 425px) {
            font-size: 16px;
        }
    }
    .caption{
        position: relative;
        height:83px ;
        img{
            border-radius: 10px;
            width: 100%;
            height:83px ;
            object-fit: cover;
        }
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            width: 108%;
            max-width: 100%;
            height: 100%;
            background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
            filter: opacity(1);
            border-radius: 0px 0px 10px 10px;
            z-index: 1;
        }
    }
}
.pass{
    color: var(--colorGreen) !important;
}
.mirar__ahora{
    font-size: 14px;
    width: 90%;
    height: 40px;
    border-radius: 20px;
    border: 2px solid var(--colorSecondary);
}
</style>