<template>
    <div class="container-fluid">
        <NavbarBuscadorBack/>
        <HeaderTitulo class="d-none d-lg-flex" :tituloHeader="$t('message.HomePresets')"/>
        <div class="row justify-content-center position-relative">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 px-lg-auto mt-lg-5 position-relative">
                <Filtros class=""/>
                <TitulosSecciones :titulo="$t('message.coursesPhotography')" :haySubtitulo="false"/>
                <div class="d-flex justify-content-center align-items-center flex-column flex-md-row">
                    <CursosFotografiaSnippet v-for="(product, id) in products_list"
                                                :rName="product.name"
                                                :rSlug="product.slug"
                                                :rOwner="product.professional.name"
                                                :rOwnerAvatar="product.professional.avatar"
                                                :rImage="product.presentations[0].url"
                                                :rModality="product.modality.translation.name"
                                                :rDetail="product.details"
                                                :rPrice="product.prices[0]"
                                                :rVerified="product.professional.verified"
                                                :rProductVerified="false"
                                                :rCreatedAt="0"
                                                :rCollabs="product.collaborators"
                                                :rReviews="product.reviews"
                                                :rMarketplace="product.marketplace"
                                                :rMembership="product.membership"
                                                :rPurchases="product.purchases_count" :key="product"/>
                                                <CursosFotografiaSnippet v-for="(product, id) in products_list"
                                                :rName="product.name"
                                                :rSlug="product.slug"
                                                :rOwner="product.professional.name"
                                                :rOwnerAvatar="product.professional.avatar"
                                                :rImage="product.presentations[0].url"
                                                :rModality="product.modality.translation.name"
                                                :rDetail="product.details"
                                                :rPrice="product.prices[0]"
                                                :rVerified="product.professional.verified"
                                                :rProductVerified="false"
                                                :rCreatedAt="0"
                                                :rCollabs="product.collaborators"
                                                :rReviews="product.reviews"
                                                :rMarketplace="product.marketplace"
                                                :rMembership="product.membership"
                                                :rPurchases="product.purchases_count" :key="product"/>
                                                <CursosFotografiaSnippet v-for="(product, id) in products_list"
                                                :rName="product.name"
                                                :rSlug="product.slug"
                                                :rOwner="product.professional.name"
                                                :rOwnerAvatar="product.professional.avatar"
                                                :rImage="product.presentations[0].url"
                                                :rModality="product.modality.translation.name"
                                                :rDetail="product.details"
                                                :rPrice="product.prices[0]"
                                                :rVerified="product.professional.verified"
                                                :rProductVerified="false"
                                                :rCreatedAt="0"
                                                :rCollabs="product.collaborators"
                                                :rReviews="product.reviews"
                                                :rMarketplace="product.marketplace"
                                                :rMembership="product.membership"
                                                :rPurchases="product.purchases_count" :key="product"/>
                                                <CursosFotografiaSnippet v-for="(product, id) in products_list"
                                                :rName="product.name"
                                                :rSlug="product.slug"
                                                :rOwner="product.professional.name"
                                                :rOwnerAvatar="product.professional.avatar"
                                                :rImage="product.presentations[0].url"
                                                :rModality="product.modality.translation.name"
                                                :rDetail="product.details"
                                                :rPrice="product.prices[0]"
                                                :rVerified="product.professional.verified"
                                                :rProductVerified="false"
                                                :rCreatedAt="0"
                                                :rCollabs="product.collaborators"
                                                :rReviews="product.reviews"
                                                :rMarketplace="product.marketplace"
                                                :rMembership="product.membership"
                                                :rPurchases="product.purchases_count" :key="product"/>
                </div>
                <BotonesSlidersHome class="d-lg-none" idNext="nextSliderCursosboton" idBack="prevSliderCursosboton"/>
                <TitulosSecciones :titulo="$t('message.coursesDiscount')" :haySubtitulo="false" class="d-none"/>
                <!-- <OrdenarPorSelect class="d-lg-none"/> -->
                <!-- <TituloYOpcionesResultadosCursos class="d-none d-lg-flex mt-lg-4" :titulo="$t('message.coursesDiscount')" :lista="2" :categoria="2" idNextV2="nextSliderCursosV2boton" idBackV2="prevSliderCursosV2boton"/>
                <SliderMobileSeccionesResultadosCursosV2/>
                <BotonesSlidersHome class="d-lg-none" idNext="nextSliderCursosV2boton" idBack="prevSliderCursosV2boton"/> -->
                <!-- <panelSeccion class="d-lg-none"/> -->
                <!-- <PanelSeccionV3 :class="filtrosDesplegados == true ? 'd-none' : 'd-flex' || mapaDesplegado == true ? 'd-none' : 'd-flex'"/> -->
                <!-- <TitulosSeccionesV2 :titulo="$t('message.exploreCategories')" :class=" filtrosDesplegados == true ? 'd-none' : 'd-flex' || mapaDesplegado == true ? 'd-none' : 'd-flex' "/> -->
                <!-- <CategoriasListado :class=" filtrosDesplegados == true ? 'd-none' : 'd-flex' || mapaDesplegado == true ? 'd-none' : 'd-flex' "/> -->
            </div>
            
            <div class="col-4 d-none d-lg-flex justify-content-center align-items-start ps-0" v-if="mapaDesplegado"></div>
            <div class="col d-none d-lg-flex justify-content-center align-items-start ps-0 mt-lg-5 position" v-if="mapaDesplegado">
                <MapaFiltros/>
            </div>
            <div class="filtros-absolute" v-if="filtrosMapa" @click="cerrarTodo">
                
            </div>
            <div class="filtros row d-flex justify-content-center align-items-start" v-if="filtrosMapa">
                <div class="col-11 d-flex justify-content-center align-items-center px-0">
                    <FiltrosV2 :funcionMostrarFiltros="funcionMostrarFiltros" :funcionMapa="funcionMapa2"/>
                </div>
                <div class="col-3 d-flex justify-content-center align-items-start ps-0">
                    <BodyFiltros/>
                </div>
                <div class="col-8 d-flex flex-column justify-content-center align-items-center px-0 position-relative">
                    <TituloYOpcionesResultadosCursos :titulo="$t('message.coursesPhotography')" idNextV2="nextSliderCursosboton" idBackV2="prevSliderCursosboton"/>
                    <SliderMobileSeccionesHomeV3 class=""/>
                    <!-- <TituloYOpcionesResultadosCursos class="mt-lg-4" :titulo="$t('message.coursesDiscount')" :lista="2" :categoria="2" idNextV2="nextSliderCursosV2boton" idBackV2="prevSliderCursosV2boton"/>
                    <SliderMobileSeccionesResultadosCursosV3 class=""/> -->
                </div>
            </div>
        </div>
        <!-- <div class="row justify-content-center mt-lg-5" :class=" filtrosDesplegados == true ? 'd-flex' : 'd-none' || mapaDesplegado == true ? 'd-flex' : 'd-none' ">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 px-lg-auto " >
                <PanelSeccionV3/>
                <TitulosSeccionesV2 :titulo="$t('message.exploreCategories')"/>
                <CategoriasListado/>
            </div>
        </div> -->
        <div class="espacio2"></div>
        <FooterHome />
        <Footer :activeHome="true" class="d-lg-none"/>
        <div class="espacio2"></div>

    </div>
</template>

<script setup>
import Filtros from "../../components/Filtros/Filtros.vue";
import FiltrosV2 from "../../components/Filtros/FiltrosV2.vue";
import Footer from "../../components/Footer/Footer.vue";
import TitulosSecciones from "../../components/Titulo/TitulosSecciones.vue";
import FooterHome from "../../components/Footer/FooterHome.vue";
import NavbarBuscadorBack from "../../components/Navs/NavbarBuscadorBack.vue";
import BotonesSlidersHome from "../../components/Botones/SlidersHome.vue";
import TituloYOpcionesResultadosCursos from "../../components/Titulo/TituloYOpciones.vue";
import HeaderTitulo from "../../components/Titulo/HeaderTitulo.vue";
import BodyFiltros from "../../components/Filtros/Body.vue";
import MapaFiltros from "../../components/Filtros/Mapa.vue";
import SliderMobileSeccionesHomeV3 from "../../components/Sliders/SeccionesHomeV3.vue";
import CursosFotografiaSnippet from "../../components/Sliders/CursosFotografia.vue";
import { useUsersStore } from "../../stores/user"
import { storeToRefs } from "pinia";
import axios from "axios";
import { ref } from "vue";

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users

const api_products = '/api/public/products'

const products_list = ref([])

const fetchProducts = async (page = 1) => {
    try {
        products_list.value = []
        const response = await axios.get(`${url}${api_products}?page=${page}`)
        console.log(response)
        products_list.value = response.data.data.data
        console.log(products_list.value)
    } catch (error) {
        console.log(error)
    }
}
fetchProducts()

</script>

<style lang="scss" scoped>
body{
    max-width: 100% !important;
}
.position{
    position: absolute;
    right: 0;
    max-width: 34%;
    height: 100%;
    top: 0;
}
.filtros-absolute{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.35);
    width: 100vw;
    min-height:  102.5%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    z-index: 5;
    
}
.filtros{
    position: absolute;
    background-color: #FFFFFF;
    max-width: 95vw;
    min-height:  102.5%;
    z-index: 10;
    left: 0;
    top: 0;
    
}
</style>