<template>
    <div class="row justify-content-center row__width" >
        <div class="col-12 d-flex justify-content-center align-items-center px-0">
            <div class="row justify-content-center align-items-center row__width">
                <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0 d-none d-lg-flex" v-if="filtrosDesplegados || mapaDesplegado">
                    <h2>{{titulo}}</h2>
                </div>
                <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start " v-else >
                    <h2>{{titulo}}</h2>
                </div>
                <div class="col-4 d-flex flex-column justify-content-start align-items-start check" 
                :class="filtrosDesplegados ? 'col-lg-7' : '' || mapaDesplegado ? 'col-lg-6' : ''">
                    <!-- <div class="row justify-content-start row__width">
                        <div class="col d-flex justify-content-start align-items-center max-width px-0 " 
                            :class="filtrosDesplegados == true ? 'filtros' : '' || mapaDesplegado  == true ? 'filtros2' : ''">
                            <input type="radio" name="estilo" :id="`categoria`+categoria">
                            <label :for="`categoria`+categoria"><i class="cuadricula"></i> {{ $t('message.grid') }}</label>
                        </div>
                        <div class="col d-flex justify-content-start align-items-center max-width sin-borde " 
                        :class="filtrosDesplegados == true ? 'filtros' : '' || mapaDesplegado  == true ? 'filtros2' : ''">
                            <input type="radio" name="estilo" :id="`lista`+lista">
                            <label :for="`lista`+lista"><i class="lista"></i> {{ $t('message.list') }}</label>
                        </div>
                    </div> -->
                </div>
                <div class="col d-flex justify-content-end align-items-center px-0" :class="filtrosDesplegados ? 'col-lg-5' : '' || mapaDesplegado ? 'col-lg-6' : ''">
                    <div class="row justify-content-center row__width">
                        <!-- <div class="col-4 contenedor d-flex justify-content-center align-items-center px-0">
                            <select name="" id="">
                                <option value="">{{$t('message.sort')}}</option>
                            </select>
                        </div> -->
                        <div class="col d-flex justify-content-center align-items-center px-0 contador__botones">
                            <div class="row justify-content-end row__width">
                                <div class="col col__perso d-flex justify-content-end align-items-center">
                                    <div :id="pagination" class="paginationStyle"></div>
                                </div>
                                <div class="col col__perso d-flex justify-content-end align-items-center">
                                    <button :id="idBackV2"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                                    <button :id="idNextV2" class="me-0"><img src="../../img/home-icons/arrow-rigth-azul.svg" alt=""></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";

    const general = useGeneralStore();
    const {textos, filtrosDesplegados, mapaDesplegado} = storeToRefs(general);
    const {} = general;

    const props = defineProps({
        titulo: '',
        categoria:{
            type: Number,
            default: 1
        },
        lista:{
            type: Number,
            default: 1
        },
        idBackV2: {
            type: String,
        },
        idNextV2: {
            type: String,
        },
        pagination: '',
    })
</script>

<style lang="scss" scoped>
h2{
    font-size: 30px;
    font-weight: 700;
    color: var(--colorSecondary);
    text-align: left;
}
.contenedor{
    position: relative;
    max-width: 37%;
    &::before{
        content: '';
        position: absolute;
        right: 0;
        width: 20px;
        height: 10px;
        background-image: url('../../img/home-icons/cursos-icons/arrow-abajo-negro.svg');
        background-repeat: no-repeat;
    }
}
select{
    border: 0;
    font-weight: 600;
    font-size: 16px;
    color: var(--colorSecondary);
    -webkit-appearance: none !important;
    height: 50px;
    width: 100%;
    &:focus{
        outline: 0;
        box-shadow: none;
    }
    
}
.check{
    label{
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        border-right: 1px solid #E9E7E7;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        cursor: pointer;
        i{
            width: 20px;
            height: 20px;
            display: block;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-right: 10px;
        }
        .lista{
            background-image: url('../../img/home-icons/cursos-icons/lista-gris.svg');
        }
        .cuadricula{
            background-image: url('../../img/home-icons/cursos-icons/cuadricula-gris.svg');
        }
    }
    input{
        display: none;
    }
    input:checked + label{
        color: var(--colorSecondary);
        font-weight: 600;
        .lista{
            background-image: url('../../img/home-icons/cursos-icons/lista-negro.svg');
        }
        .cuadricula{
            background-image: url('../../img/home-icons/cursos-icons/cuadricula-negro.svg');
        }
    }
    .max-width{
        max-width: 35%;
    }
    .sin-borde{
        label{
            border: 0;
        }
    }
    .filtros{
        max-width: 25%;
    }
    .filtros2{
        max-width: 35%;
    }
}
.contador__botones{
    margin: 20px 0px;
    p, h3{
        font-weight: 700;
        font-size: 18px;
        color: var(--colorSecondary);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 3px;
    }
    h3{
        font-weight: 400;
        padding-left: 3px;
    }
    button{
        background-color: var(--bgPrincipal);
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #F5F5F5;
        border-radius: 21px;
        width: 42px;
        height: 42px;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.col__perso{
    max-width: max-content;
}
.paginationStyle {
    font-weight: 700!important;
    font-size: 18px!important;
    color: var(--colorSecondary)!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    padding-right: 3px!important;
    span {
        font-weight: 400!important;
        padding-left: 3px!important;
    }
}
</style>