<template>
    <!-- <div class="d-flex flex-column justify-content-start align-items-center galeries pt-3 pb-3 px-1 me-3" @click="actual_gallery = gallery, actual_tab = 2" v-for="(gallery, id) in gallery.specializations">
        <img :src="gallery.files[0].file" alt="" class="img">
        <div class="d-flex sub-info ps-3 pt-2 row__width">
            <img :src="avatar" alt="avatar" class="avatar">
            <div class="col d-flex flex-column max-change">
                <h5 class="title px-3 pt-1 mb-2">{{ gallery.translation.name }}</h5>
                <div class="col d-flex justify-content-start align-items-center px-3">
                    <div class="col-6 d-flex justify-content-start align-items-center">
                        <img src="../../img/perfil-icons/galeria-icons/like-red.svg" alt="">
                        <h5 class="mt-1 ms-2">{{ gallery.files[0].likes }}</h5>
                    </div>
                    <div class="col-6 d-flex justify-content-start align-items-center">
                        <img src="../../img/perfil-icons/galeria-icons/visualizaciones.svg" alt="" class="icon">
                        <h5 class="mt-1 ms-2">{{ gallery.files[0].views }}</h5>
                    </div>
                </div>
            </div>
            
        </div>
    </div> -->
    <div class="col-3 col-lg-4 d-flex flex-column justify-content-start align-items-center galeries pt-3 pb-3 px-1 me-3" @click="actual_gallery = gallery, actual_tab = 2" v-for="(gallery, id) in gallery.specializations">
        <img :src="gallery.files[0].file" alt="" class="img">
        <div class="row row__width justify-content-center align-items-center sub-info pt-2">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center max-change px-0">
                <div class="row row__width d-flex justify-content-start align-items-center">
                    <div class="col-12 d-flex justify-content-start align-items-center">
                        <h5 class="title pt-1 mb-2">{{ gallery.translation.name }}</h5>
                    </div>
                    <div class="col-6 d-flex justify-content-start align-items-center">
                        <img src="../../img/perfil-icons/galeria-icons/like-red.svg" alt="">
                        <h5 class="mt-1 ms-2">{{ gallery.files[0].likes }}</h5>
                    </div>
                    <div class="col-6 d-flex justify-content-start align-items-center">
                        <img src="../../img/perfil-icons/galeria-icons/visualizaciones.svg" alt="" class="icon">
                        <h5 class="mt-1 ms-2">{{ gallery.files[0].views }}</h5>
                    </div>
                    <div class="col-12 d-flex justify-content-end align-items-center pt-3">
                        <h6>Ver más</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { inject, ref } from 'vue';

    const props = defineProps({
        avatar: '',
        gallery:{
            type: Object
        }
    })

    const actual_tab = inject('actual_tab')
    const actual_gallery = inject('actual_gallery')
    
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 5px;
    .swiper-wrapper{
        margin: 20px 0;
    }
}
.galeries{
    background-color: transparent;
    border-radius: 20px;
    max-height: max-content;
    max-width: 250px;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0px 3px 10px #00000029;
    padding: 20px;
    &:hover{
        box-shadow: 0px 3px 6px #00000029;
        background-color: #fff;
    }
    .img{
        height: 200px;
        width: 220px;
        object-fit: cover;
        border-radius: 20px;
    }
    h5{
        text-align: left;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.2;
        text-align: left;
        overflow: hidden;
    }
}

.sub-info{
    .title{
        font-weight: 700;
    }
    img{
        width: 20px;
        height: 20px;
    }
    .icon{
        width: 22px;
        height: 22px;
    }
    .avatar{
        min-height: 50px;
        min-width: 50px;
        max-height: 50px;
        max-width: 50px;
        object-fit: cover;
        border-radius: 50%;
    }
}
.galeries{
    background-color: transparent;
    border-radius: 20px;
    max-height: max-content;
    max-width: 250px;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0px 3px 10px #00000029;
    padding: 20px;
    &:hover{
        box-shadow: 0px 3px 6px #00000029;
        background-color: #fff;
        cursor: pointer;
    }
    .img{
        height: 200px;
        width: 220px;
        object-fit: cover;
        border-radius: 20px;
    }
    h5{
        font-weight: 700;
        font-size: 18px;
        text-align: left;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.2;
        text-align: left;
        overflow: hidden;
    }
    h6{
        font-weight: 500;
        font-size: 14px;
        color: var(--colorSecondary);
        text-align: left;
        &:hover, &:focus{
            color: var(--colorPrimary);
        }
    }
}
</style>