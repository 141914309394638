<template>
    <div class="row justify-content-between margin-top row__width">
        <div class="col-10 col-lg d-flex justify-content-center align-items-center ps-0 max-width-filtros">
            <button class="contenedor" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                <img src="../../img/home-icons/filtros.svg" alt="">
                <h3>{{$t('message.showFilters')}}</h3>
                <span><img src="../../img/home-icons/ver-mas.svg" alt=""></span>
            </button>
        </div>
        <div class="col-lg d-flex justify-content-center align-items-center d-none d-lg-flex">
            <div class="contenedor2 row__width">
                <h3>{{$t('message.search')}}</h3>
                <form class="w-100 d-flex justify-content-between" @submit.prevent v-on:keydown.enter="inputValue">
                    <input type="text" :placeholder="$t('message.placeholderSearch')" @submit.prevent v-model.trim="X">
                    <button type="button" @click="inputValue"><img src="../../img/home-icons/lupa.svg" alt=""></button>
                </form>
            </div>
        </div>
        <div class="col-2 col-lg d-flex justify-content-center align-items-center desk-max-width">
            <!-- <a href="" data-bs-toggle="offcanvas" data-bs-target="#offcanvasUbicacion" aria-controls="offcanvasUbicacion"><img src="../../img/home-icons/ubicacion-icon.svg" alt=""></a> -->
        </div>
        
        <div class="offcanvas offcanvas-start col-12 h-100" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div class="offcanvas-body row justify-content-center">
                <div class="col-11 d-flex justify-content-center align-items-start px-1">
                    <div class="row justify-content-center row__width mt-2">
                        <button type="button" class="col-12 contenedor mb-4 z-index-fix" data-bs-dismiss="offcanvas" aria-label="Close">
                            <img src="../../img/home-icons/filtros.svg" alt="">
                            <h3>{{$t('message.hideFilters')}}</h3>
                            <span><img src="../../img/home-icons/cerrar.svg" alt=""></span>
                        </button>
                        <!-- PRODUCTOS Y CATEGORIAS -->
                        <div class="col-12 div__blanco">
                            <div class="row justify-content-center filters">
                                <div class="col-12 d-flex flex-column justify-content-start align-items-start">
                                    <h2 class="pt-0">{{$t('message.products')}}</h2>
                                    <div class="justify-content-start align-items-start text-start px-0 w-100" v-for="(prod, index) in prodList" :key="prod">
                                        <p :class="prod.active ? 'active' : prodList.some(item => item.active) ? 'd-none' : ''" @click="selectProd(prod)">{{prod.name}}</p>
                                        <p :class="modality.active ? 'active' : ''" class="ms-3" v-if="prod.active" @click="selectModality(modality, prod.types)" v-for="(modality, id) in prod.types">{{ modality.name }}</p>
                                    </div>
                                </div>
                                <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                                    <h2>{{$t('message.categories')}}</h2>
                                    <div class="justify-content-start align-items-start text-start px-0 w-100" v-for="(prof , index) in profList" :key="prof">
                                        <p :class="prof.active ? 'active' : profList.some(item => item.active) ? 'd-none' : ''" @click="selectProf(prof)">{{prof.name}}</p>
                                        <p :class="spec.active ? 'active' : ''" class="ms-3" v-if="prof.active" @click="selectSpec(spec, prof.types)" v-for="(spec, id) in prof.types">{{ spec.name }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- CREAR UN PACK (WTF) -->
                        <button class="col-12 contenedor div__blanco d-none">
                            <h2 class="">{{$t('message.createPack')}}</h2>
                            <span><img src="../../img/home-icons/ver-mas.svg" alt=""></span>
                        </button>
                        <div class="col-12 px-0">
                            <div class="accordion" id="accordionExample">
                                <!-- PONENTES -->
                                <div class="accordion-item div__blanco d-none">
                                    <h2 class="accordion-header p-0" id="headingOne">
                                        <button class="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            Ponentes
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="row justify-content-center ponente" v-for="(ponente, id) in ponentes">
                                                <div class="col-9 d-flex justify-content-start align-items-center">
                                                    <div class="position-relative">
                                                        <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt=""  
                                                        class="verificado" :class="ponentes[id].verificado == true ? '' : 'd-none'">
                                                        <img :src="ponentes[id].foto" alt="" class="perfil">
                                                    </div>
                                                    <h3>{{ponentes[id].nombre}}</h3>
                                                </div>
                                                <div class="col-3 d-flex justify-content-end align-items-center px-2">
                                                    <button class="mas" :id="id">+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- PONENTES -->
                                <div class="accordion-item div__blanco d-none">
                                    <h2 class="accordion-header p-0" id="headingThree">
                                        <button class="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Ponentes
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body flex-column">

                                        </div>
                                    </div>
                                </div>
                                <!-- PROVEEDORES -->
                                <div class="accordion-item div__blanco d-none">
                                    <h2 class="accordion-header p-0" id="headingThree">
                                        <button class="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Proveedores
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body flex-column">

                                        </div>
                                    </div>
                                </div>
                                <!-- PRECIOS -->
                                <div class="accordion-item div__blanco">
                                    <h2 class="accordion-header p-0" id="headingThree">
                                        <button class="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Precio
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body d-flex justify-content-between align-items-center" v-if="priceRange">
                                            <input class="col-5 input-price" type="number" id="minimum" v-model="priceRange.minimum" pattern="^[0-9]+([,.][0-9]+)?$" placeholder="Mínimo" @input="reSearch = !reSearch"> - 
                                            <input class="col-5 input-price" type="number" id="maximum" v-model="priceRange.maximum" pattern="^[0-9]+([,.][0-9]+)?$" placeholder="Máximo" @input="reSearch = !reSearch">
                                        </div>
                                    </div>
                                </div>
                                <!-- UBICACION -->
                                <div class="accordion-item div__blanco d-none">
                                    <h2 class="accordion-header p-0" id="headingTwo">
                                        <button class="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            {{$t('message.location')}}
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="row justify-content-start">
                                                <div class="col-12 d-flex flex-column justify-content-start align-items-start before__texto scroll">
                                                    <div class="div__contenedor" v-for="(ubicacion, id) in ubicaciones">
                                                        <input type="checkbox" :id="`ubicacion`+id">
                                                        <label :for="`ubicacion`+id">{{ubicaciones[id].titulo}}</label>
                                                    </div>
                                                </div>
                                                <div class="col-7">
                                                    <BTNVerMas :funcionTextoVerMas="verMas2"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                            <button class="filtrar" data-bs-dismiss="offcanvas" aria-label="Close">
                                {{$t('message.cancel')}}
                            </button>
                            <!-- <button class="filtrar" data-bs-dismiss="offcanvas" aria-label="Close"> 
                                <img src="../../img/home-icons/filtrar-blanco.svg" alt="">
                                {{$t('message.filter')}}
                            </button>
                            <a href="" class="cancelar" data-bs-dismiss="offcanvas" aria-label="Close">{{$t('message.cancel')}}</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!------>
        <div class="offcanvas offcanvas-end h-100" tabindex="-1" id="offcanvasUbicacion" aria-labelledby="offcanvasUbicacionLabel">
            <div class="offcanvas-body row justify-content-center mapa__seccion">
                <div class="col-12 d-flex flex-column justify-content-start align-items-center px-0  position-relative">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.0168878894465!2d-58.38375908423284!3d-34.60373446500723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4aa9f0a6da5edb%3A0x11bead4e234e558b!2sObelisco!5e0!3m2!1ses-419!2sar!4v1668362752555!5m2!1ses-419!2sar" 
                    width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <div class="row justify-content-center row__width mt-2 position top">
                        <div class="col-11 d-flex justify-content-center align-items-center">
                            <button type="button" class="col-12 contenedor mb-4 z-index-fix" data-bs-dismiss="offcanvas" aria-label="Close">
                                <div class="azul"><img src="../../img/home-icons/ubicacion-icon-blanco.svg" alt=""></div>
                                <h3 class="mapa">Ocultar Mapa</h3>
                                <span><img src="../../img/home-icons/cerrar.svg" alt=""></span>
                            </button>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width position center botones">
                        <div class="col-11 d-flex justify-content-center align-items-center position-relative">
                            <div class="pin">
                                <div class="position-relative">
                                    <div class="num">10</div>
                                    <img src="../../img/mapa-icons/pin-ejemplo.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row zoom botones">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                            <button><span>+</span></button>
                            <button><span>-</span></button>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width position end  botones">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-end my-3">
                            <button class=""><span><img src="../../img/mapa-icons/lock.svg" alt=""></span></button>
                        </div>
                        <div class="col-12 d-flex justify-content-end align-items-center">
                            <button class=""><span><img src="../../img/mapa-icons/arrow-left-negro.svg" alt=""></span></button>
                            <button class=""><span><img src="../../img/mapa-icons/arrow-right-negro.svg" alt=""></span></button>
                            <button class="azul"><span><img src="../../img/mapa-icons/position.svg" alt=""></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import NavbarBuscadorBack from "../Navs/NavbarBuscadorBack.vue"
import BTNVerMas from "../Botones/VerMas.vue"
import CategoriaResultadosCursos from "../Categorias/CategoriaResultadosCursos.vue"
import { ref, provide, inject, onUnmounted, onBeforeUnmount } from 'vue'
import { useUsersStore } from "../../stores/user"
import { useGeneralStore } from "../../stores/general"    
import { storeToRefs } from "pinia"
import axios from 'axios'

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users

const general = useGeneralStore()
const { reSearch } = storeToRefs(general)
const {  } = general

let prodList = ref()
let profList = ref()

let searchValue = ref('')
let X = ref('')

let priceRange = inject('priceRange')

const fetchProdList = async() => {
    let lang = 'ES'
    try {
        await axios.get(url+'/api/categories/classes/linked/'+lang).then(result => {
            console.log(result)
            prodList.value = result.data
        })
    } catch (error) {
        console.log(error)
    }
}
fetchProdList()

const fetchProfList = async() => {
    let lang = 'ES'
    try {
        await axios.get(url+'/api/categories/linked/'+lang).then(result => {
            console.log(result)
            profList.value = result.data
        })
    } catch (error) {
        console.log(error)
    }
}
fetchProfList()

if (sessionStorage.getItem('searchValue')){
    //console.log(sessionStorage.getItem('searchValue'))
    X.value = JSON.parse(sessionStorage.getItem('searchValue'))
}

//Seteo el parametro de busqueda
const inputValue = () => {
    searchValue.value = X.value
    sessionStorage.setItem('searchValue', JSON.stringify(searchValue.value))
    reSearch.value = !reSearch.value
    return {reSearch}
}

const verMas1 = () => {
    console.log('hola 1')
    let ocultarChecks = document.querySelector('div.desaparecer')
    ocultarChecks.classList.toggle('d-none')
    document.getElementById('texto1').classList.toggle('before__texto')
}

const verMas2 = () => {
    console.log('hola')
}

const selectProd = (prod) => {
    let activeProds = []
    prodList.value.forEach((item, index) => {
        if(item.id == prod.id){
            if(!item.active){
                item.active = true
            } else{
                item.active = false
            }
            sessionStorage.removeItem('searchValueModality')
            item.types.forEach(item => {
                item.active = false
            })
        } else {
            item.active = false
            sessionStorage.removeItem('searchValueProd')
        }
        if(item.active){
            activeProds.push(item)
        }
        if(index == prodList.value.length - 1){
            if(activeProds.length == 0){
                sessionStorage.removeItem('searchValueProd')
            } else{
                sessionStorage.setItem('searchValueProd', JSON.stringify(activeProds))
            }
        }
    })
    reSearch.value = !reSearch.value
}

const selectModality = (modality, modalityList) => {
    let activeModality = []
    modalityList.forEach((item, index) => {
        if(item.id == modality.id){
            if(!item.active){
                item.active = true
            } else{
                item.active = false
            }
        } else{
            item.active = false
            sessionStorage.removeItem('searchValueModality')
        }
        if(item.active){
            activeModality.push(item)
        }
        if(index == modalityList.length - 1){
            if(activeModality.length == 0){
                sessionStorage.removeItem('searchValueModality')
            } else{
                sessionStorage.setItem('searchValueModality', JSON.stringify(activeModality))
            }
        }
    })
    reSearch.value = !reSearch.value
}

const selectProf = (prof) => {
    let activeProfs = []
    profList.value.forEach((item, index) => {
        if(item.id == prof.id){
            if(!item.active){
                item.active = true
            } else{
                item.active = false
            }
            sessionStorage.removeItem('searchValueSpec')
            item.types.forEach(item => {
                item.active = false
            })
        } else {
            item.active = false
            sessionStorage.removeItem('searchValueProf')
        }
        if(item.active){
            activeProfs.push(item)
        }
        if(index == profList.value.length - 1){
            if(activeProfs.length == 0){
                sessionStorage.removeItem('searchValueProf')
            } else{
                sessionStorage.setItem('searchValueProf', JSON.stringify(activeProfs))
            }
        }
    })
    reSearch.value = !reSearch.value
}

const selectSpec = (spec, specList) => {
    let activeSpecs = []
    specList.forEach((item, index) => {
        if(item.id == spec.id){
            if(!item.active){
                item.active = true
            } else{
                item.active = false
            }
        } else{
            item.active = false
            sessionStorage.removeItem('searchValueSpec')
        }
        if(item.active){
            activeSpecs.push(item)
        }
        if(index == specList.length - 1){
            if(activeSpecs.length == 0){
                sessionStorage.removeItem('searchValueSpec')
            } else{
                sessionStorage.setItem('searchValueSpec', JSON.stringify(activeSpecs))
            }
        }
    })
    reSearch.value = !reSearch.value
}

onBeforeUnmount(() => {
    sessionStorage.removeItem('searchValueProd')
    sessionStorage.removeItem('searchValueModality')
    sessionStorage.removeItem('searchValueProf')
    sessionStorage.removeItem('searchValueSpec')
})

</script>


<style lang="scss" scoped>
.margin-top{
    @media (min-width: 992px) {
        margin-top: 95px;
    }
}
.contenedor{
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    border: 1px solid #ECECEC;
    border-radius: 40px;
    height: 66px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    h3{
        color: #374557;
        font-size: 16px;
        font-weight: 600;
        padding: 5px 10px;
    }
    span{
        position: absolute;
        right: 7px;
        border: 0;
        min-height: 50px;
        width: 50px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bgPrincipal);
        img{
            max-height: 20px;
        }
    }
    .azul{
        position: absolute;
        left: 7px;
        height: 60px;
        width: 60px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--colorPrimary); 
    }
    .mapa{
        padding-left: 23%;
    }
}
.div__blanco{
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 10px;
    min-height: 88px;
    margin: 0px 0px 25px 0px;
    padding: 30px;
    h2{
        font-weight: 600;
        font-size: 20px;
        color: #374557;
        padding: 15px 0px;
    }
    p, label{  
        font-weight: 400;
        font-size: 16px;
        padding: 0px 0px 7px 0px;
        color: #697891;
    }
    .active{
        font-weight: 700;
        color: var(--colorPrimary);
    }
    
}
.accordion{
    .accordion-item{
        .accordion-button{
            font-weight: 600;
            font-size: 20px;
            color: #374557;
            padding: 0px 0px;
            background-color: transparent;
            border: 0;
            box-shadow: none;
            border-radius: 0;
            &:focus{
                box-shadow: none;
            }
        }
        .accordion-body{
            padding: 10px 0;
            h3{
                font-weight: 500;
                font-size: 16px;
                color: #697891;
                padding-left: 5px;
            }
            .perfil{
                max-height: 41px;
            }
            .verificado{
                position: absolute;
                top: 0;
                right: 0;
                max-height: 14px;
            }
            .mas{
                border: 2px solid var(--colorPrimary);
                background-color: var(--bgPrincipal);
                border-radius: 25px;
                height: 28px;
                width: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--colorPrimary);
                font-size: 15px;
                font-weight: 600;
            }
            .scroll{
                max-height: 20vh;
                overflow-x: hidden;
                overflow-y: scroll;
                .div__contenedor{
                    margin-bottom: 10px;
                    width: 98%;
                }
                input{
                    display: none;
                }
                input + label{  
                    font-weight: 400;
                    font-size: 16px;
                    padding: 0px 0px 7px 0px;
                    color: #697891;
                    width: 100%;
                    height: 44px;
                    padding-left: 15px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 0;
                }
                input:checked + label{
                    background-color: #F8F8F9;
                    border-radius: 22px;
                    font-weight: 600;
                    color: var(--colorPrimary);
                }
                &::-webkit-scrollbar{
                    background-color: rgba(219, 219, 219, 0.25);
                    width: 8px;
                    border-radius: 20px;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #DBDBDB;
                    border-radius: 50px;
                }
            }
        }
        .accordion-button::after{
            background-image: url('../../img/home-icons/cursos-icons/arrow-abajo-negro.svg');
            background-size: 80%;
            background-position: center;
        }
        .ponente{
            padding: 10px 0px;
            &:first-child{
                padding-top: 15px;
            }
        }
    }
}
.filtrar{
    background-color: var(--colorPrimary);
    color: #fff;
    width: 100%;
    height: 50px;
    border-radius: 28px;
    font-weight: 600;
    font-size: 16px;
    color: #FAFAFA; 
    border: 0;
    img{
        padding: 0px 5px;
    }
}
.cancelar{
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #697891;
}
.mapa__seccion{
    padding: 0;
    .position{
        position: absolute;
    }
    .top{
        top: 3%;
    }
    .center{
        top: 7%;
        min-height: 70vh;
    }
    .end{
        bottom: 2%;
        button{
            margin-left: 10px;
        }
    }
    .zoom{
        position: fixed;
        top: 50vh;
        right: 10px;
        button{
            margin: 0 0 10px 0px;
        }
    }
    .pin{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: var(--bgPrincipal);
        box-shadow: 0px 3px 6px #00000029;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        .num{
            position: absolute;
            top: 0;
            right: 0px;
            width: 47px;
            height: 47px;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            font-size: 21px;
            font-weight: 600;
            color: #FAFAFA;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .botones{
        button{
            background-color: var(--bgPrincipal);
            box-shadow: 0px 3px 10px #00000029;
            border-radius: 10px;
            border: 0;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
                width: 27px;
                height: 27px;
                box-shadow: 0px 3px 6px #00000029;
                background-color: var(--bgPrincipal);
                border-radius: 52px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000000;
                font-weight: 600;
                font-size: 16px;
            }
            &:focus{
                outline: 0;
            }
        }
        .azul{
            background-color: var(--colorPrimary);
        }
    }
}
.offcanvas{
    width: 100% !important;
}
@media (min-width: 992px) {
    .max-width-filtros{
        max-width: 18%;
    }
    .desk-max-width{
        max-width: 5%;
    }
    .margin-top{
        margin-top: 35px;
    }
    .offcanvas{
        max-width: 350px !important;
        top: 95px !important;
        max-height: calc(100vh - 95px) !important;
    }
}
.contenedor2{
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 50px;
    min-height: 66px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 20px;
    h3{
        color: var(--colorSecondary);
        font-size: 18px;
        font-weight: 500;
        padding: 0px 0px;
        @media (min-width: 992px) {
            font-size: 16px;
        }
    }
    input{
        border: 0;
        color: #B8C1DB;
        font-weight: 400;
        font-size: 15px;
        width: 100%;
        &::placeholder{
            color: #B8C1DB;
        }
        &:focus{
            outline: 0;
        }
    }
    button{
        position: absolute;
        right: 10px;
        top: 6px;
        border: 0;
        min-height: 53px;
        width: 53px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        background-color: var(--colorPrimary);
        justify-content: center;
        align-items: center;
        img{
            padding: 0;
        }
    }
    .border-left{
        border-left: 1px solid rgba(184, 193, 219, 0.4);
    }
    
}
.z-index-fix{
    z-index: 1046;
}
.filters{
    p{
        cursor: pointer;
    }
    p:first-letter, h2:first-letter{
        text-transform: uppercase;
    }
    p:hover{
        font-weight: 700;
    }
}
.filtrar-boton{
    background-color: var(--colorPrimary);
    color: #fff;
    width: 75%;
    height: 35px;
    border-radius: 28px;
    font-weight: 600;
    font-size: 16px;
    color: #FAFAFA; 
    border: 0;
    img{
        padding: 0px 5px;
    }
}

.input-price{
    border: 1px solid #69789149;
    border-radius: 10px;
    font-weight: 400;
    font-size: 12px;
    padding: 3px 5px;
    &:focus{
        outline: 0;
        border-color: var(--colorPrimary);
    }
}
</style>