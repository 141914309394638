<template>
    <div class="row justify-content-center row__width">
        <div class="col-12 d-flex flex-column justify-content-center align-items-center boder-top">
            <div class="row justify-content-center row__width">
                <div class="col-12 d-flex justify-content-center align-items-center addReferrals px-0 mt-4">
                    <div class="row justify-content-center row__width">
                        <div class="col-12 d-flex justify-content-start align-items-center mb-3">
                            <button class="btn_none px-0 d-flex justify-content-center align-items-center pe-3" @click.prevent="setView(0)">
                                <img src="../../../../img/home-icons/arrow-left-negra.svg" alt="">
                            </button>
                            <h4>Administrar beneficios</h4>
                        </div>
                    </div>  
                </div>
                <!--- COLLAPSE PADRE --->
                <div class="col-12 d-flex flex-column justify-content-center align-items-center collapses px-0 mb-3">
                    <div class="accordion d-flex flex-column justify-content-center align-items-center" id="accordionFather">
                        <div class="accordion-item row row__width justify-content-center">
                            <h2 class="accordion-header px-0" id="heading">
                                <button class="accordion-button collapsed w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false" aria-controls="collapse">
                                    <div class=" d-flex flex-column justify-content-center align-items-start ps-4">
                                        <h3>Beneficios</h3>
                                        <h4>Auí puede configurar los beneficios de sus productos</h4>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapse" class="col-12 accordion-collapse collapse" aria-labelledby="heading">
                                <div class="accordion-body row justify-content-center px-0">
                                    <!--- COLLAPSE BENEFICIOS --->
                                    <div class="row justify-content-start row__width">
                                        <!--- AÑADIR BENEFICIO --->
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0" >
                                            <div class="row justify-content-center row__width">
                                                <div class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-start px-0">
                                                    <h3>Añadir beneficio</h3>
                                                </div>
                                                <div class="col-12 col-lg-4 d-flex  justify-content-center align-items-start px-0">
                                                    <button type="button" class="agregar-cursos-btn" @click="showInputAdd(1)">
                                                        <span>
                                                            <img src="../../../../img/panel-icons/mas.svg" alt="">
                                                        </span>
                                                        <p>Añadir nuevo beneficio</p>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--- BENEFICIOS --->
                                        <div class="accordion d-flex flex-column justify-content-center align-items-center collapses2 addReferrals px-0" id="accordionExample3">
                                            <div class="accordion-item row row__width justify-content-center align-items-start" v-for="(benefit, id) in listBenefits">
                                                <h2 class="accordion-header d-flex px-0 justify-content-center" :id="'headingBenefit'+id">
                                                    <div class="row row__width justify-content-center">
                                                        <div class="col-12 d-flex px-0 justify-content-center py-4">
                                                            <div class="row row__width justify-content-center">
                                                                <div class="col-12 d-flex px-4 justify-content-start align-items-center">
                                                                    <div class="d-flex px-0 justify-content-start align-items-center w-100">
                                                                        <span v-if="benefit.icon">
                                                                            <img :src="benefit.icon" alt="" class="icons">
                                                                        </span>
                                                                        <span v-else>
                                                                            <img src="../../../../img/subir-productos-icons/cursos-icons/bandera.svg" alt="">
                                                                        </span>
                                                                        <div class=" d-flex flex-column justify-content-center align-items-start ps-3">
                                                                            <h3 v-if="benefit.translations.es">{{ benefit.translations.es }}</h3>
                                                                            <h3 v-else>Nuevo beneficio</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div class="collapsed position-relative col-1 d-flex justify-content-end align-items-center me-1" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseBenefit'+id" aria-expanded="false" :aria-controls="'collapseBenefit'+id">
                                                                        <button type="button" class="button">
                                                                            <img src="../../../../img/subir-productos-icons/cursos-icons/editar.svg" alt="">
                                                                        </button>
                                                                    </div>
                                                                    <div class="d-flex justify-content-end align-items-center pe-0">
                                                                        <button type="button" class="button" @click="deleteBenefit(benefit.id)">
                                                                            <img src="../../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="">
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div :id="'collapseBenefit'+id" class="col-12 accordion-collapse collapse px-0" :aria-labelledby="'headingBenefit'+id">
                                                            <div class="accordion-body row justify-content-center">
                                                                <div class="col-12 d-flex justify-content-center align-items-center">
                                                                    <div class="row  row__width justify-content-end px-3">
                                                                        <div class="col-12 d-flex flex-column justify-content-start align-items-start agregar-idioma mt-3 mt-lg-0">
                                                                            <div class="row justify-content-center row__width">
                                                                                <h3 class="px-0 pb-3">
                                                                                    Seleccionar íconos
                                                                                </h3>
                                                                            </div>
                                                                            <div class="row justify-content-start row__width" >
                                                                                <div class="col d-flex justify-content-center align-items-center position-relative" v-for="(icon, index) in listIcons">
                                                                                    <input type="radio" :id="'icon'+index+id" :name="'icons'+index+id" :value="icon" v-model="benefit.icon" class="image-radio d-none">
                                                                                    <label :for="'icon'+index+id" class="image-label">
                                                                                        <img :src="icon" alt="" class="icons">
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 d-flex justify-content-center align-items-center mt-3">
                                                                    <div class="row  row__width justify-content-end">
                                                                        <div class="col d-flex justify-content-center align-items-starts pe-0">
                                                                            <input type="text" name="" id="benefitName" v-model="benefit.translations.es">
                                                                            <button type="button" :id="'btn-save'+id" class="button btn-save max-width ms-2" @click="editBenefit(benefit, benefit.translations.es, benefit.translations.id, 'btn-save'+id)">
                                                                                <i class="save"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </h2>
                                            </div>
                                            <div class="col-12 px-0 add-benefit mt-2 d-none" id="addBenefitInputs">
                                                <div class="row justify-content-center w-100">
                                                    <div class="col-12 d-flex justify-content-center align-items-center">
                                                        <div class="row  row__width justify-content-end px-3">
                                                            <div class="col-12 d-flex flex-column justify-content-start align-items-start agregar-idioma mt-3 mt-lg-0">
                                                                <div class="row justify-content-center row__width">
                                                                    <h3 class="px-0 pb-3">
                                                                        Seleccionar íconos
                                                                    </h3>
                                                                    <button type="button" class="button me-4 mt-3" @click="addCheck(1)">
                                                                        <img src="../../../../img/subir-productos-icons/incorrect.svg" alt="">
                                                                    </button>
                                                                </div>
                                                                <div class="row justify-content-start row__width">
                                                                    <div class="col d-flex justify-content-center align-items-center position-relative" v-for="(icon, index) in listIcons">
                                                                        <input type="radio" :id="'icon2'+icon" :name="'icons2'+icon" :value="icon" v-model="selectedIcon" class="image-radio d-none">
                                                                        <label :for="'icon2'+icon" class="image-label">
                                                                            <img :src="icon" alt="" class="icons">
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-flex justify-content-center align-items-center mt-3">
                                                        <div class="row  row__width justify-content-end">
                                                            <div class="col d-flex justify-content-center align-items-starts pe-0">
                                                                <input type="text" name="" id="benefitName" v-model="beneName">
                                                                <button type="button" class="button max-width ms-2" @click="addBenefit(beneName)">
                                                                    <img src="../../../../img/subir-productos-icons/cursos-icons/save.svg" alt="">
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--- FIN COLLAPSE BENEFICIOS --->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- FIN COLLAPSE PADRE --->
                <div class="col-12 d-flex justify-content-center align-items-center add-benefit addReferrals px-0">
                    <div class="row justify-content-center row__width">
                        <div class="col-12 d-flex justify-content-start align-items-center mb-3">
                            <h4>Administrar íconos</h4>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                            <div class="row justify-content-center row__width">
                                <div class="col-12 d-flex justify-content-center align-items-center">
                                    <div class="row justify-content-start row__width">
                                        <div class="col d-flex justify-content-center align-items-center position-relative col_icon px-0 me-2" v-for="(icon, index) in listIcons">
                                            <button type="button" class="position" @click="deleteIcon(icon)"><img src="../../../../img/subir-productos-icons/close-azul.svg" alt=""></button>
                                            <img :src="icon" alt="" class="icons">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-start align-items-center mt-3">
                                    <input type="file" id="file-icon-benefit" ref="sendIcons" multiple v-on:change="addIcons">
                                    <label for="file-icon-benefit" class="">{{ $t('message.uploadAFile') }}</label>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject} from "vue"
import axios from 'axios';
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../../stores/user"

const users = useUsersStore();
const {  } = storeToRefs(users);
const { url } = users;
const urlAddIcons = '/api/profits/add/icons'
const urlFetchIcons = '/api/profits/icons'
const urlDeleteIcons = '/api/profits/delete/icon'
const urlDefaultIcons = '/api/profits/default/icon'

const urlAddBenefit = '/api/profits/add'
const urlEditBenefit = '/api/profits/edit/translation/'
const urlDeleteBenefit = '/api/profits/remove/'
const urlFetchBenefits = '/api/profits/get/all/'

const sendIcons = ref(null)
const defaultIcon = ref(null)
const selectedIcon = ref(null)
const beneName = ref(null)
const beneIcon = ref(null)
const listIcons = inject('listIcons')
const listBenefits = inject('listBenefits')

const views = inject('views')
const buttons = inject('buttons')

const showInputAdd = (action) => {
    if (action == 1){
        let element = document.getElementById('addBenefitInputs')
        element.classList.remove('d-none')
    }
}
const addCheck = (action) => {
    if (action == 1){
        let element = document.getElementById('addBenefitInputs')
        element.classList.add('d-none')
    }
    //console.log(listProfs.value)
}

const addBenefit = async(name) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    const parts = selectedIcon.value.split('/');
    const icon = parts.pop();
    const lang = 'ES'
    addCheck(1)
    try {
        await axios.post(url+urlAddBenefit, { name: name, icon: icon, lang: lang}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 200){
                fetchBenefits()
            }
            else if(result.status == 422){
                console.log('Error de validacion')
            }
            else {
                console.log('Roldan lo rompio todo')
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const editBenefit = async(benefit, name, translate, benefitId) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    console.log(benefit)
    const id = benefit.id
    const parts = benefit.icon.split('/');
    const icon = parts.pop();
    const lang = 'ES'
    try {
        await axios.post(url+urlEditBenefit+id+'/'+translate, {name: name, icon: icon, lang: lang}, {headers: headers.value}).then((result) => {
            console.log(result)
            fetchBenefits()
            saveQuestionsIcon(1, benefitId)
        })
    } catch (error) {
        console.error(error)
        saveQuestionsIcon(2, benefitId)
    }
}

const deleteBenefit = async(id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.delete(url+urlDeleteBenefit+id, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 200){
                fetchBenefits()
                console.log('Eliminado')
            }
            else if (result.status == 403){
                console.log('No se puede borrar')
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const fetchBenefits = async() => {
    try {
        await axios.get(url+urlFetchBenefits+'ES').then((result) => {
            //console.log(result)
            listBenefits.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listBenefits.value.push( result.data[i] )
                }
            }
            console.log(listBenefits.value)
        })
    } catch (error) {
        console.error(error)
    }
}
fetchBenefits()

const addIcons = async() => {
    const formData = new FormData()
    for (let i = 0; i < sendIcons.value.files.length; i++) {
        formData.append('icon[]', sendIcons.value.files[i]);
    }
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlAddIcons, formData, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                fetchIcons()
            }
            else if(result.status == 422){
                console.log('Error de validacion')
            }
            else {
                console.log('Roldan lo rompio todo')
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const fetchIcons = async() => {
    try {
        await axios.get(url+urlFetchIcons).then((result) => {
            console.log(result)
            listIcons.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listIcons.value.push( result.data[i] )
                }
            }
            //console.log(listIcons.value)
        })
    } catch (error) {
        console.error(error)
    }
}
fetchIcons()

const deleteIcon = async(icon) => {
    var partes = icon.split('/');
    var file = partes[partes.length - 1];
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlDeleteIcons, {icon: file}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 200){
                fetchIcons()
                console.log('Eliminado')
            }
            else if (result.status == 403){
                console.log('No se puede borrar')
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const setDefaultIcon = async() => {
    const formData = new FormData()
    formData.append('icon', defaultIcon.value.files[0])
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlDefaultIcons, formData, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                fetchIcons()
            }
            else if(result.status == 422){
                console.log('Error de validacion')
            }
            else {
                console.log('Roldan lo rompio todo')
            }
        })
    } catch (error) {
        console.error(error)
    }
}


const setView = (x) =>{
    //console.log('me ejecuto')
    views.value = x
    buttons.value = true
    return {buttons}
}

const saveQuestionsIcon = (x, benefitId) => {
    let button = document.getElementById(benefitId)
    if(x == 1){
        button.classList.add('done')
        setTimeout(() => {
            button.classList.remove('done')
        }, 1500)
    }else{
        button.classList.add('undone')
        setTimeout(() => {
            button.classList.remove('undone')
        }, 1500)
    }
}
</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.contenedor{
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 40px;
    min-height: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    @media (min-width: 992px) {
        border-radius: 50px;
        padding: 22px 15px;
    }
    @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
    } 
    .addReferrals{
        input{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
            @media (max-width: 991px) {
                width: 83%;
            }
        }
        .button{
            background-color: #fff;
            border: 2px solid #E9E3E3;
            outline: 0;
            border-radius: 25px;
            min-height: 40px;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 20px;
            }
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
        }
        h5{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 16px;
            text-align: left;
            padding-top: 30px;
        }
        h3{
            margin-bottom: 0;
            text-align: left;
        }
        span{
            border: 2px solid #E9E3E3;
            min-height: 50px;
            min-width: 50px;
            max-width: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        input[type=file]{
            display: none;
        }
        input[type=file] + label{
            background-color: #fff;
            border: 2px dashed #B8BCC8;
            height: 50px;
            width: 140px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            color: #B8BCC8;
            &:focus, &:hover{
                border: 2px dashed var(--colorPrimary);
                color: var(--colorPrimary);
            }
        }
        .col_icon{
            max-width: max-content;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            .position{
                position: absolute;
                top: -25px;
                right: -5px;
                min-height: 20px;
                width: 20px;
                margin-top: 20px;
                background-color: #fff;
                border: 2px solid #E9E3E3;
                outline: 0;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    height: 8px;
                    width: 8px;
                }
            }
        }
    }   
    .agregar-idioma{
        .col{
            max-width: 40px;
            margin-right: 15px;
        }
        button{
            background-color: var(--bgPrincipal);
            border: 0;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            max-height: 24px;
            max-width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: -7px;
            top: -5px;
        }
    }
}
.classSpecial{
   @media (min-width: 992px) {
        width: 70vw !important;
   }
}
.collapses{
    h3{
        font-weight: 700;
        font-size: 18px;
    }
    .question{
        font-size: 30px;
        margin-bottom: 0 !important;
    }
    h4{
        font-weight: 400;
        font-size: 15px;
        color: #B8BCC8;
        text-align: left;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            margin: 15px 0px;
            border: 0;
            border-radius: 10px;
            
            p{
                font-size: 14px;
                font-weight: 400;
                color: var(--colorSecondary);
            }
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-header{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #F9F9F9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            .accordion-button{
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: #F9F9F9;
                width: auto;
                &:focus{
                    border-color: inherit;
                    box-shadow: none;
                }
                @media (max-width: 991px) {
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
            }
            .accordion-button::after{
                position: absolute;
                right: 5%;
                background-image: url('../../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                background-size: 100%;
                background-position: center;
                @media (max-width: 991px) {
                    margin-top: 5px;
                }
            }
        }
    }
    .max-width{
        width: 7%;
    }
    .position-relative{
        .position{
            position: absolute;
            right: 8%;
            button{
                background-color: transparent;
                border: 2px solid #E4E4E4;
                border-radius: 50%;
                min-height: 40px;
                min-width: 40px;
                
            }
        }
    }
    .collapses3{
        margin-top: 0;
        margin-bottom: 10px;
    }
}
.collapses2{
    h3{
        margin-bottom: 0px !important;
        font-size: 16px;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            margin: 15px 0px;
            border: 0;
            border-radius: 10px;
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-button{
            border-radius: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: var(--bgPrincipal);
            max-width: 50px !important;
            &:focus{
                border-color: inherit;
                box-shadow: none;
            }
            
        }
        .accordion-button:not(.collapsed){
            background-color: inherit;
            box-shadow: none;
        }
        .accordion-button::after{
            position: absolute;
            right: 2.5%;
            background-image: url('../../../../img/subir-productos-icons/arrow-gris-abajo.svg');
            background-size: 50%;
            background-position: center;
            border: 2px solid #E9E3E3;
            border-radius: 50%;
            min-height: 40px;
            min-width: 40px;
        }
        .accordion-header{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            span{
                border: 2px solid #E9E3E3;
                min-height: 40px;
                min-width: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                h3{
                    text-align: center;
                }
            }
            button{
                background-color: transparent;
                border: 2px solid #E4E4E4;
                border-radius: 50%;
                min-height: 40px;
                min-width: 40px;
                
            }
            .accordion-body{
                input,select{
                    width: 100%;
                    border: 2px solid #E9E3E3;
                    border-radius: 10px;
                    background-color: #FCFCFC;
                    height: 50px;
                    padding-left: 15px;
                    outline: 0;
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    -webkit-appearance: none;
                    &:focus, &:hover{
                        border: 2px solid #1679FC;
                    }
                    &::placeholder{
                        color: #697891;
                    }
                }
                span{
                    border: 2px solid #E9E3E3;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        text-align: center;
                    }
                }
                .button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    min-height: 40px;
                    min-width: 40px;
                    margin-left: 10px;
                }
            }
        }
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            min-height: 40px;
            min-width: 40px;
            
        }
    }
    
    @media (min-width: 992px) {
        margin: 20px 0 20px 0;
    }
}
.agregar-cursos-btn{
    box-shadow: 0 3px 6px #00000016;
    border-radius: 43px;
    height: 60px;
    margin: 10px 0;
    border: 0;
    background-color: var(--bgPrincipal);
    padding-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    max-width: 300px;
    p{
        font-weight: 600 !important;
        font-size: 16px !important;
        color: var(--colorSecondary);
    }
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
    }
}
.border-top{
    border-top: 2px solid #E4E4E4;
    padding-top: 20px;
}
.icons{
    max-width: 48px;
    max-height: 48px;
    min-width: 48px;
    min-height: 48px;
    object-fit: cover;
    border-radius: 50%;
}
.add-benefit {
    box-shadow: 0px 3px 10px #00000029;
    background-color: #F9F9F9;
    border-radius: 10px;
    min-height: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
}
.image-radio + .image-label img {
    border: 1px solid #bdbdbd;
    padding: 2px;
}
.image-radio:checked + .image-label img {
    border: 3px solid #1679FC;
    padding: 0px;
}
.btn-save{
    i{
        min-width: 23px;
        min-height: 23px;
        display: block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('../../../../img/subir-productos-icons/cursos-icons/save.svg');

    }
    transition: all 0.2s;
}
.done{
    i{
        background-image: url('../../../../img/subir-productos-icons/cursos-icons/correct.svg');
    }
    border: 2px solid #66DF4A !important;
}
.undone{
    i{
        background-image: url('../../../../img/subir-productos-icons/cursos-icons/incorrect.svg');
    }
    border: 2px solid #FF576E !important;
}
</style>