<template>
    <div class="row justify-content-center justify-content-lg-start row__width mx-5" :class="loading ? 'loader' : ''">
        <swiper-container
            :navigation="{
                nextEl: '#nextSliderFotosboton',
                prevEl: '#prevSliderFotosboton'
            }"
            :pagination="{
                el: '#pagProfesional',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :slides-per-view="1"
            :slides-per-group="1"
            :spaceBetween="20"
            :breakpoints="{
                375: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                992: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                1875: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            }"
            class="mySwiper">
            <swiper-slide v-for="(professional, id) in professional_list" class="d-flex justify-content-center align-items-start" style="height: auto !important;">
                <div class="col-12 d-flex flex-column justify-content-start align-items-center slide px-0" style="height: 400px;" @click="router.push({name: 'PerfilUsuario', params: {slug: professional.slug}})">
                    <div class="row justify-content-center row__width pt-3 imagenes" v-if="professional.images.length == 3">
                        <div class="col d-flex justify-content-center align-items-center pe-0 position-relative">
                            <img :src="professional.images[0].file" alt="image-1" class="foto_principal">
                            <span>{{professional.ranking}}</span>
                        </div>
                        <div class="col d-flex justify-content-center align-items-between arreglo">
                            <div class="row justify-content-center">
                                <div class="col-12 d-flex justify-content-center align-items-center padding">
                                    <img :src="professional.images[1].file" alt="image-2" class="foto_secundaria">
                                </div> 
                                <div class="col-12 d-flex justify-content-center align-items-center padding">
                                    <img :src="professional.images[2].file" alt="image-3" class="foto_secundaria">
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width pt-3 imagenes" v-else>
                        <div class="col d-flex justify-content-center align-items-center pe-0 position-relative">
                            <img :src="professional.avatar" alt="image-1" class="foto_principal">
                            <span>{{professional.ranking}}</span>
                        </div>
                        <div class="col d-flex justify-content-center align-items-between arreglo">
                            <div class="row justify-content-center">
                                <div class="col-12 d-flex justify-content-center align-items-center padding">
                                    <img :src="professional.avatar" alt="image-2" class="foto_secundaria">
                                </div> 
                                <div class="col-12 d-flex justify-content-center align-items-center padding">
                                    <img :src="professional.avatar" alt="image-3" class="foto_secundaria">
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center descripcion row__width">
                        <div class="col d-flex justify-content-center align-items-start max-width">
                            <img :src="professional.avatar" alt="avatar" class="foto_perfil">
                        </div>
                        <div class="col-9 d-flex justify-content-center align-items-center px-0">
                            <div class="row justify-content-center row__width">
                                <div class="col-12 d-flex justify-content-start align-items-center">
                                    <h3 class="text-fix">{{ professional.name }}</h3>
                                    <!-- <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="ms-2"> -->
                                </div>
                                <div class="col-12 d-flex justify-content-start align-items-center" v-if="professional.country && professional.location">
                                    <h4 class="text-fix">{{ countryCodeToFlagEmoji(professional.country.code) }} {{ professional.location }}</h4>
                                </div>
                                <div class="col-12 d-flex justify-content-start align-items-center">
                                    <h6 class="text-fix" >
                                        <span v-for="(specialization, index) in professional.specializations" :key="specialization">
                                            {{ specialization.translation.name }}<span v-if="index < professional.specializations.length - 1">, </span>
                                        </span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span class="borde"></span>
                    <div class="row justify-content-center comprar row__width d-none">
                        <div class="col-5 d-flex flex-column justify-content-center align-items-start ps-4">
                            <h2>{{$t('message.from')}}</h2>
                            <h5 class="text-fix">{{ professional.slug }}</h5>
                        </div>
                        <div class="col-7 d-flex justify-content-center align-items-center">
                            <BTNPrimario url="/perfil-usuario/" :slug="professional.slug" :textoBTNPrimario="$t('message.viewProfile')"/>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <!-- SKELETON -->
            <swiper-slide v-for="(professional, id) in 4" class="d-flex justify-content-center align-items-start" style="height: auto !important;" v-if="professional_list.length <= 0">
                <div class="col-12 d-flex flex-column justify-content-start align-items-center slide px-0">
                    <div class="row justify-content-center row__width pt-3 imagenes">
                        <div class="col d-flex justify-content-center align-items-center pe-0 position-relative">
                            <div class="foto_principal_skeleton"></div>
                        </div>
                        <div class="col d-flex justify-content-center align-items-between arreglo">
                            <div class="row justify-content-center">
                                <div class="col-12 d-flex justify-content-center align-items-center padding">
                                    <div class="foto_secundaria_skeleton"></div>
                                </div> 
                                <div class="col-12 d-flex justify-content-center align-items-center padding">
                                    <div class="foto_secundaria_skeleton"></div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center descripcion row__width">
                        <div class="col d-flex justify-content-center align-items-start max-width">
                            <div class="foto_perfil_skeleton"></div>
                        </div>
                        <div class="col-9 d-flex justify-content-center align-items-center px-0">
                            <div class="row justify-content-center row__width">
                                <div class="col-12 d-flex justify-content-start align-items-center">
                                    <div class="text_skeleton text_skeleton_large"></div>
                                </div>
                                <div class="col-12 d-flex justify-content-start align-items-center">
                                    <div class="text_skeleton text_skeleton_medium"></div>
                                </div>
                                <div class="col-12 d-flex justify-content-start align-items-center">
                                    <div class="text_skeleton text_skeleton_small"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center comprar row__width d-none">
                        <div class="col-5 d-flex flex-column justify-content-center align-items-start ps-4">
                            <div class="text_skeleton text_skeleton_medium"></div>
                        </div>
                        <div class="col-7 d-flex justify-content-center align-items-center">
                            <div class="btn_skeleton"></div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination" />
        </swiper-container>
    </div>
    <div class="pt-5 pb-5" :class="!loading && professional_list.length == 0 ? '' : 'd-none'">
        <h1>{{ $t('message.withoutResults') }}</h1>
    </div>
</template>

<script setup>
import BTNPrimario from "../Botones/PrimarioRutas.vue";

import { useGeneralStore } from "../../stores/general"
import { useUsersStore } from "../../stores/user"
import { storeToRefs } from "pinia";
import { ref, watch, nextTick, onMounted } from 'vue';
import { useI18n } from "vue-i18n";
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';

import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";

onMounted(() => {
    polyfillCountryFlagEmojis();
});

function countryCodeToFlagEmoji(countryCode) {
    return countryCode
        .toUpperCase()
        .replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt()));
}

const router = useRouter()
const route = useRoute();

const general = useGeneralStore();
const {textos, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
const {} = general;

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users

const {locale} = useI18n({ useScope: 'global' })
const lang = ref()
const X = ref('')
const gallery = ref([])

const urlApi = '/multi-search'

const loading = ref(true);

//Setea parametros de busqueda para el post
const search = ref({
    queries:[{
        indexUid: 'professionals',
        q:''
    }]
})

//Precarga parametros de resultados de busqueda
const childProducts = ref(0)
const sliderLength = ref(0)

const professional_list = ref([])

//Setea las variables con los resultados para su uso
// const name = childProducts X
// const description = childProducts X
// const image = childProducts X
// const location = childProducts X
// const slug = childProducts X
// const imageGallery1 = childProducts X
// const imageGallery2 = childProducts X
// const imageGallery3 = childProducts X
// const price = childProducts X

//Setea valores segun el lenguaje -- POSIBLEMENTE NO SE USE EN EL FUTURO --
const setLang = () => {
    lang.value = locale.value
    //console.log(lang.value)
}
setLang()

const getProfList = async () => {
    try {
        const response = await axios.get(`${url}/api/public/professionals/top`)
        console.log(response)
        professional_list.value = response.data.data
        loading.value = false
    } catch (error) {
        console.log(error)
        loading.value = false
    }
}
getProfList()

//Hago un post del usuario x
// const fetchSearch = async () => {
//     let prof = null
//     let spec = null
//     let lang = 'ES'
//     delete search.value.queries[0].filter
//     if (sessionStorage.getItem('searchValue')){
//         X.value = JSON.parse(sessionStorage.getItem('searchValue'))
//         search.value.queries[0].q = X.value
//     }
//     if(sessionStorage.getItem('searchValueProf')){
//         prof = JSON.parse(sessionStorage.getItem('searchValueProf'))
//         prof.forEach(profession => {
//             if(!search.value.queries[0].filter){
//                 search.value.queries[0].filter = `professions.name.${lang} = ${profession.name}`
//             } else{
//                 search.value.queries[0].filter += ` AND professions.name.${lang} = ${profession.name}`
//             }
//         })
//     }
//     if(sessionStorage.getItem('searchValueSpec')){
//         spec = JSON.parse(sessionStorage.getItem('searchValueSpec'))
//         spec.forEach(speciality => {
//             if(!search.value.queries[0].filter){
//                 search.value.queries[0].filter = `specializations.name.${lang} = ${speciality.name}`
//             } else{
//                 search.value.queries[0].filter += ` AND specializations.name.${lang} = ${speciality.name}`
//             }
//         })
//     }
//     setLang()
//     try {
//         await axios.post(url+urlApi, search.value).then((result) => {
//             childProducts.value = null
//             sliderLength.value = null
//             console.log(result)
//             nextTick(() => {
//                 childProducts.value = result.data.results[0].hits
//                 sliderLength.value = result.data.results[0].hits.length
//             })
//             sessionStorage.removeItem('searchValue')
//         })
//     }
//     catch (error) {
//     console.log(error)
//     }
// }
// fetchSearch()

// watch( reSearch, async () => {
//     let prof = null
//     let spec = null
//     let lang = 'ES'
//     delete search.value.queries[0].filter
//     if (sessionStorage.getItem('searchValue')){
//         X.value = JSON.parse(sessionStorage.getItem('searchValue'))
//         search.value.queries[0].q = X.value
//     }
//     if(sessionStorage.getItem('searchValueProf')){
//         prof = JSON.parse(sessionStorage.getItem('searchValueProf'))
//         prof.forEach(profession => {
//             if(!search.value.queries[0].filter){
//                 search.value.queries[0].filter = `professions.name.${lang} = ${profession.name}`
//             } else{
//                 search.value.queries[0].filter += ` AND professions.name.${lang} = ${profession.name}`
//             }
//         })
//     }
//     if(sessionStorage.getItem('searchValueSpec')){
//         spec = JSON.parse(sessionStorage.getItem('searchValueSpec'))
//         spec.forEach(speciality => {
//             if(!search.value.queries[0].filter){
//                 search.value.queries[0].filter = `specializations.name.${lang} = ${speciality.name}`
//             } else{
//                 search.value.queries[0].filter += ` AND specializations.name.${lang} = ${speciality.name}`
//             }
//         })
//     }
//     setLang()
//     try {
//         await axios.post(url+urlApi, search.value).then((result) => {
//             childProducts.value = null
//             sliderLength.value = null
//             console.log(result)
//             nextTick(() => {
//                 childProducts.value = result.data.results[0].hits
//                 sliderLength.value = result.data.results[0].hits.length
//             })
//             sessionStorage.removeItem('searchValue')
//         })
//     }
//     catch (error) {
//     console.log(error)
//     }
// })
</script>

<style lang="scss" scoped>
.text-fix {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.swiper{
    width: 100%;
    min-height: 100%;
    padding: 20px 5px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.mySwiper{
    padding: 20px 5px;
}
.slide{
    max-height: 450px;
    cursor: pointer;
    .imagenes{
        .padding{
            padding: 0px 10px 10px 10px;
            &:last-child{
                padding-bottom: 0;
            }
        }
        img{
            max-width: 100%;
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
            box-shadow: 0px 3px 6px #00000029;
        }
        .arreglo{
            max-width: 33%;
        }
        span{
            background-color: #fff;
            box-shadow: 0px 3px 6px #00000029;
            height: 47px;
            width: 47px;
            border-radius: 50%;
            color: var(--colorPrimary);
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 20px;
            top: 6%;
        }
        .foto_principal{
            height: 238px;
        }
        .foto_secundaria{
            height: 114px;
        }
    }
    .descripcion{
        padding: 20px 0px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .foto_perfil{
            border-radius: 50%;
            height: 72px;
            width: 72px;
            object-fit: cover;
        }
        .max-width{
            max-width: 25%;
            padding-right: 0;
        }
        h3{
            font-size: 22px;
            font-weight: 700;
            color: #374557;
            text-align: left;
        }
        h4{
            font-size: 16px;
            font-weight: 400;
            color: var(--colorSecondary);
            padding: 10px 0px;
        }
        h6{
            font-weight: 400;
            font-size: 14px;
            color: #B8C1DB;
            text-align: left;
        }
    }
    .comprar{
        padding: 0px 0px 10px 0;
        h2{
            color: #B8C1DB;
            font-weight: 400;
            font-size: 16px;        
        }
        h5{
            font-weight: 700;
            font-size: 23px;
            color: var(--colorSecondary);
        }
        @media (min-width: 992px) {
            padding: 10px 0;
        }
    }
    .borde{
        width: 95%;
        height: 1px;
        background-color: rgba(184, 193, 219, 0.3);
    }
}
.paginationStyle {
    font-weight: 700!important;
    font-size: 18px!important;
    color: var(--colorSecondary)!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    padding-right: 3px!important;
    span {
        font-weight: 400!important;
        padding-left: 3px!important;
    }
}
.mySwiperDirectorio{
    display: flex;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: calc((100% - 30px) / 3.3) !important;

    display: flex;
    justify-content: center;
    align-items: center;
}


.foto_principal_skeleton,
.foto_secundaria_skeleton,
.foto_perfil_skeleton,
.text_skeleton,
.btn_skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    animation: skeleton-loading 1.2s infinite linear;
}

.foto_principal_skeleton {
    width: 100%;
    height: 240px;
}

.ranking_skeleton {
    width: 50px;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.foto_secundaria_skeleton {
    width: 80px;
    height: 105px;
    margin-bottom: 10px;
}

.foto_perfil_skeleton {
    width: 72px;
    height: 72px;
    border-radius: 50%;
}

.text_skeleton {
    height: 15px;
    margin-bottom: 10px;
}

.text_skeleton_large {
    width: 70%;
    margin-top: 7px;
}

.text_skeleton_medium {
    width: 50%;
}

.text_skeleton_small {
    width: 30%;
}

.borde_skeleton {
    width: 100%;
    height: 2px;
    background-color: #e0e0e0;
    margin: 10px 0;
}

.btn_skeleton {
    width: 100px;
    height: 30px;
    border-radius: 4px;
}

@keyframes skeleton-loading {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}
</style>