<template>
    <div class="row justify-content-center row__width inputs">
        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
            <h3>{{$t('message.gender')}}</h3>
            <div class="col-12 d-flex justify-content-between align-items-start">
                <input type="radio" id="Male" value="MALE" name="gender" v-model.trim="usuariosRegistro.gender">
                <label for="Male" :class="estados.gender">
                    <div class="span"></div>
                    <i class="hombre"></i>
                    <p>Male</p>
                </label>
                <input type="radio" id="Female" value="FEMALE" name="gender" v-model.trim="usuariosRegistro.gender">
                <label for="Female" :class="estados.gender">
                    <div class="span"></div>
                    <i class="mujer"></i>
                    <p>Female</p>
                </label>
                <input type="radio" id="None" value="NONE" name="gender" v-model.trim="usuariosRegistro.gender">
                <label for="None" :class="estados.gender">
                    <div class="span"></div>
                    <i class="ambos"></i>
                    <p>None</p>
                </label>
            </div>
        </div>
    </div>
</template>

<script setup>
// Importo mi método
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../stores/user";
import { useGeneralStore } from "../../stores/general"    
import { storeToRefs } from "pinia";
import { inject } from 'vue';

const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;
const estados = inject('estados')
// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {usuariosRegistro} = storeToRefs(users);
const {} = users;
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
input{
    display: none;
}
label{
    font-size: 16px;
    color: #697891;
    font-weight: 400;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    height: 50px;
    width: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    i{
        background-repeat: no-repeat;
        background-size: cover;
        width: 25px;
        height: 26px;
        display: block;
        margin-right: 10px;
    }
    .hombre{
        background-image: url('../../img/panel-icons/hombre.svg');
    }
    .mujer{
        background-image: url('../../img/panel-icons/mujer.svg');
    }
    .ambos{
        background-image: url('../../img/panel-icons/ambos.svg');
    }
}
label:hover {
    cursor: pointer;
}
input:checked + label{
    border-color: var(--colorPrimary);
    color: var(--colorPrimary);
    &::before{
        content: '';
        position: absolute;
        top: -10px;
        right: -7px;
        background-image: url('../../img/panel-icons/input-valido.svg');
        background-repeat: no-repeat;
        z-index: 1;
        display: block;
        width: 25px;
        height: 25px;
    }
    .hombre{
        background-image: url('../../img/panel-icons/hombre-active.svg');
    }
    .mujer{
        background-image: url('../../img/panel-icons/mujer-active.svg');
    }
    .ambos{
        background-image: url('../../img/panel-icons/ambos-active.svg');
    }
}
</style>