<template>
    <div class="" :class="clase == true ? 'padre2' : 'padre'">
        <li class="d-flex justify-content-between align-items-center">
            {{ $t('message.currency') }}
            <div class="d-flex justify-content-center align-items-center">
                <p>{{ coinSelected }}</p>
                <img src="../../../img/nav-icons/arrow-rigth-negro.svg" alt="">
            </div>
            <ul class="dropdown-menu paises2 dropdown-submenu" style="max-height: max-content;">
                <li v-for="(coin, id) in coins" :key="coin" @click="setCoin(coin.abbr)"
                    class="d-flex justify-content-between align-items-center dropdown-item">
                    <div class="d-flex justify-content-center align-items-center">
                        <p class="ps-2">{{ coin.abbr }}</p>
                    </div>
                </li>
            </ul>
        </li>
        <li class="d-flex justify-content-between align-items-center">
            {{ $t('message.language') }}
            <div v-if="locale == 'ES'" class="d-flex justify-content-center align-items-center">
                <p>{{ $t('message.spanish') }}</p>
                <img src="../../../img/nav-icons/arrow-rigth-negro.svg" alt="">
            </div>
            <div v-if="locale == 'en'" class="d-flex justify-content-center align-items-center">
                <p>{{ $t('message.english') }}</p>
                <img src="../../../img/nav-icons/arrow-rigth-negro.svg" alt="">
            </div>
            <ul class="dropdown-menu paises2 dropdown-submenu" style="max-height: max-content;">
                <li v-for="(lang, id) in languages" :key="lang" @click="setLang(lang.abbr)"
                    class="d-flex justify-content-between align-items-center dropdown-item">
                    <div class="d-flex justify-content-center align-items-center">
                        <p class="ps-2">{{ lang.name }}</p>
                    </div>
                </li>
            </ul>
        </li>
    </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios"
import { useGeneralStore } from "../../../stores/general"
import { useLanguageStore } from "../../../stores/langs"
import { useConfigStore } from "../../../stores/config";
import { storeToRefs } from "pinia"
import { useRouter } from 'vue-router'
import { useI18n } from "vue-i18n"
import { useUsersStore } from "../../../stores/user";

const { locale } = useI18n({ useScope: 'global' })
const general = useGeneralStore()
const langs = useLanguageStore()

const { textos } = storeToRefs(general)
const router = useRouter()
const { url, urlLangList, urlSetLang, setDefaultLang, setLang } = langs

const user = useUsersStore();
const { headers, idUser } = storeToRefs(user);
const { } = user;

const config = useConfigStore();
const { coins, coinSelected} = storeToRefs(config);



const props = defineProps({
    clase: {
        type: Boolean,
        default: false
    }
})

const languages = ref({})

const languagesList = async () => {
    try {
        await axios.get(url + urlLangList).then((result) => {
            languages.value = result.data.data
            //console.log(languages.value)
        })
    } catch (error) {
        //console.log(error)
    }
}
languagesList()

const setCoin = (coin) => {
    coinSelected.value = coin
    localStorage.setItem('coin', JSON.stringify(coin))
    console.log(coinSelected.value)
}
</script>

<style lang="scss" scoped>
nav {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    height: 68px;

    a {
        img {
            max-height: 50px;

            @media (min-width: 992px) {
                max-height: 50px;
            }
        }
    }

    .container-fluid {
        height: 100%;
    }

    .navbar-toggler {
        border: 0;
        padding: 0;
        outline: 0;

        .navbar-toggler-icon {
            background-image: url('../../../img/menu-icons/menu-icon.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            height: 25px;
            width: 29px;
        }

        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }

    .nav-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 10px 0px;
        position: relative;

        img {
            margin-right: 20px;
        }

        .position {
            position: absolute;
            right: 0;
        }

        i {
            margin-right: 15px;
            min-height: 23px;
            min-width: 23px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }

        .cursos {
            background-image: url('../../../img/menu-icons/cursos.svg');
        }

        .categorias {
            background-image: url('../../../img/menu-icons/categorias.svg');
        }

        .escuela {
            background-image: url('../../../img/menu-icons/escuela.svg');
        }

        .concursos {
            background-image: url('../../../img/menu-icons/concursos.svg');
        }

        .nosotros {
            background-image: url('../../../img/menu-icons/nosotros.svg');
        }

        .contacto {
            background-image: url('../../../img/menu-icons/contacto.svg');
        }

        .premium {
            background-image: url('../../../img/menu-icons/premium.svg');
        }

        &:focus,
        &:hover {
            color: var(--colorPrimary);
            font-weight: 700;

            &::before {
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -8.5%;
                top: 0;
            }

            .cursos {
                background-image: url('../../../img/menu-icons/cursos_active.svg');
            }

            .categorias {
                background-image: url('../../../img/menu-icons/categorias_active.svg');
            }

            .escuela {
                background-image: url('../../../img/menu-icons/escuela_active.svg');
            }

            .concursos {
                background-image: url('../../../img/menu-icons/concursos_actives.svg');
            }

            .nosotros {
                background-image: url('../../../img/menu-icons/nosotros_active.svg');
            }

            .contacto {
                background-image: url('../../../img/menu-icons/contacto_active.svg');
            }

            .premium {
                background-image: url('../../../img/menu-icons/premium-active.svg');
            }
        }
    }

    .navbar-brand {
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);

        p {
            font-weight: 700;
            font-size: 22px;
            color: var(--colorPrimary);
        }

        @media (min-width: 992px) {
            font-size: 30px;

            p {
                font-size: 30px;
            }
        }
    }

    .dropdown-toggle::after {
        border: 0;
    }

    ul {
        h4 {
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }

        li {
            padding: 0;
            cursor: pointer;
        }
    }
    .paises {
        border-radius: 20px;
        background-color: var(--bgPrincipal);
        right: 10%;
        padding: 10px 20px;
        width: 250px;

        li {
            width: 100%;
            padding: 10px 0px 10px 0px;

            img {
                margin-left: 10px;
            }

            .nav-link {

                &:focus,
                &:hover {
                    color: var(--enlacesNav);

                    &::before {
                        display: none;
                    }
                }
            }
        }

        .borde {
            border-top: 1px solid #c6bacc7c;
            padding: 15px 0px;

            img {
                margin-right: 10px;
                margin-left: 0;
            }
        }
    }
}

.padre {
    position: relative;
    li {
        padding: 10px 0px 10px 0px;

        img {
            margin-left: 10px;
        }

        .nav-link {

            &:focus,
            &:hover {
                color: var(--enlacesNav);

                &::before {
                    display: none;
                }
            }
        }

        &:focus,
        &:hover {
            .paises2 {
                display: flex !important;
            }
        }

        .paises2 {
            position: absolute;
            flex-direction: column;
            right: -128px;
            bottom: -54px;
        }
    }
}

.padre2 {
    position: relative;
    li {
        padding: 10px 0px 10px 0px;

        img {
            margin-left: 10px;
        }

        .nav-link {

            &:focus,
            &:hover {
                color: var(--enlacesNav);

                &::before {
                    display: none;
                }
            }
        }

        &:focus,
        &:hover {
            .paises2 {
                display: flex !important;
            }
        }

        .paises2 {
            position: absolute;
            flex-direction: column;
            left: -140px;
            top: 0;

            @media (max-width: 991px) {
                max-width: 100%;
                right: 0;
                left: auto;
                bottom: -99px;

                li {
                    padding-left: 10px;
                }
            }
        }
    }
}
</style>