<template>
    <div class="col-5 col-lg-4 d-flex flex-column justify-content-center justify-content-lg-between align-items-center productos me-4" :class="slider[id].disabled == true ? 'disabled' : ''" v-for="(slide, id) in slider">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-start px-0">
                <h5>{{ slider[id].titulo }}</h5>
                <h4>{{ slider[id].subtitulo }}</h4>
            </div>
            <div class="col-12 d-flex justify-content-between align-items-center px-0">
                <h2 v-if="slide.titulo == 'Productos'">{{ courses_uploaded }}</h2>
                <h2 v-if="slide.titulo == 'Imágenes'">{{ images_uploaded }}</h2>
                <img :src="slider[id].srcA" alt="" class="img_a" :class="slider[id].class">
                <img :src="slider[id].srcD" alt="" class="img_d" :class="slider[id].class">
            </div>
        </div>
    </div>
    <!-- <div class="col d-flex flex-column justify-content-between justify-content-lg-start align-items-center productos disabled">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex justify-content-between align-items-start px-0">
                <h2>images_uploaded</h2>
            </div>
            <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-start px-0">
                <h5>titulo</h5>
                <h4>subtitulo</h4>
            </div>
        </div>
    </div> -->
</template>

<script setup>
    import { ref } from 'vue';

    const props = defineProps({
        courses_uploaded: '',
        images_uploaded: ''
    })

    const slider = ref([
        {
            titulo: 'Productos',
            subtitulo: 'Productos subidos hasta el momento',
            srcA: require('../../img/perfil-icons/video.svg'),
            srcD: require('../../img/perfil-icons/video.svg'),
            disabled: false,
            class: 'camera'
        },
        {
            titulo: 'Imágenes',
            subtitulo: 'Imágenes subidas hasta el momento',
            srcA: require('../../img/perfil-icons/gallery.svg'),
            srcD: require('../../img/perfil-icons/gallery.svg'),
            disabled: false
        },         
    ])

    const switchTab = () => {
        let profile = document.getElementById('perfil')
        let btnProfile = document.getElementById('perfil-tab')
        let servicies = document.getElementById('servicios')
        let btnServicies = document.getElementById('servicios-tab')

        profile.classList.remove('show')
        profile.classList.remove('active')
        btnProfile.classList.remove('active')
        servicies.classList.add('show')
        servicies.classList.add('active')
        btnServicies.classList.add('active')
    }
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 5px;
    .swiper-wrapper{
        margin: 20px 0;
    }
}
.productos{
    background-color: #fff;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 20px;
    max-height: max-content;
    padding: 20px;
    h2{
        font-weight: 700;
        font-size: 35px;
        color: var(--colorSecondary);
        text-align: left;
        width: 100%;
    }
    h5{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        text-align: left;
        padding: 10px 0;
    }
    h4{
        font-weight: 400;
        font-size: 14px;
        color: #697891;
        text-align: left;
        width: 100%;
        padding-bottom: 10px;
    }
    img{
        height: 30px;
        width: 30px;
    }
    .camera{
        width: 32px;
    }
    .img_d{
        display: none;
    }
    @media (min-width: 992px) {
        min-height: 15vh;
    }
    @media (min-width: 1500px) {
        max-width: none;
        min-width: 350px;
    }
}
.productos__div{
    background-color: #fff;
    box-shadow: 0px 3px 15px #00000029;
    border: 2px solid var(--colorPrimary);
    border-radius: 41px;
    height: 100%;
    width: 82px;
    padding: 20px 0;
    h5{
        color: var(--colorPrimary);
        font-weight: 600;
        font-size: 16px;
    }
}
.disabled{
    h2, h5{
        color: #B8C1DB;
    }
    .img_a{
        display: none;
    }
    .img_d{
        display: block;
    }
}

</style>